import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Modal } from 'reactstrap';
import { createEntity, updateEntity } from './company-accounts-reducer';
import { updateEntityProperty } from '../accounts/accounts.reducer';

interface CompanyInitialRequirementProps {
  onFormComplete: (completed: boolean) => void;
}

export interface CompanyInitialRequirementRef {
  syncCompanyInitialRequirementWithServer: () => Promise<any>;
}

const CompanyInitialRequirement: React.ForwardRefRenderFunction<CompanyInitialRequirementRef, CompanyInitialRequirementProps> = ({ onFormComplete }: CompanyInitialRequirementProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();

  const [investError, setInvestError] = useState('');
  const [formValid, setFormValid] = useState(false);

  useImperativeHandle(ref, () => ({
    syncCompanyInitialRequirementWithServer
  }));

  const syncCompanyInitialRequirementWithServer = async (): Promise<any> => {
    // if(Object.prototype.hasOwnProperty.call(Object(mainAccountsEntity), 'accountId') && mainAccountsEntity?.accountId !== '') {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 1 }));
    } else {
      const formBody: any = {
        accountId: '',
        is_company_account: true,
        // accountId: AccountIdNum,
        accountMasterType: 'Starlight Smart Reserve',
        accountHolderType: 'Organization',
        investmentAmount: mainAccountsEntity?.investmentAmount || '',
        productType: [mainAccountsEntity?.USDReserve ? 'USD Reserve' : null, initialRequirements?.EURReserve ? 'EUR Reserve' : null].filter(
          value => value !== null,
        ),
        isAgeOverAgreed: mainAccountsEntity?.smartReserveAccountConfirm?.over18 || false,
        beneficialOwnerAgreed: mainAccountsEntity?.smartReserveAccountConfirm?.beneficialOwner || false,
        residentInIrelandAgreed: mainAccountsEntity?.smartReserveAccountConfirm?.residentInIreland || false,
        lumpSumAvailableToInvest: mainAccountsEntity?.smartReserveAccountConfirm?.minimumInvestment || false,
        privacyPolicy: mainAccountsEntity?.smartReserveAccountConfirm?.privacyPolicy || false,
        last_level: 1,
      };
      try {
        const response = await dispatch(createEntity(formBody));
        return response;
      } catch (error) {
        console.error('Error creating entity:', error);
        throw error;
      }
    }

  };

  const [initialRequirements, setInitialRequirement] = useState({
    investmentAmount: mainAccountsEntity?.investmentAmount || '',
    EURReserve: mainAccountsEntity?.EURReserve || '',
    USDReserve: mainAccountsEntity?.USDReserve || '',

    smartReserveAccountConfirm: {
      companyAccount: true,
      over18: mainAccountsEntity?.smartReserveAccountConfirm?.over18 || false,
      beneficialOwner: mainAccountsEntity?.smartReserveAccountConfirm?.beneficialOwner || false,
      residentInIreland: mainAccountsEntity?.smartReserveAccountConfirm?.residentInIreland || false,
      taxResidentAndPPS: mainAccountsEntity?.smartReserveAccountConfirm?.taxResidentAndPPS || false,
      minimumInvestment: mainAccountsEntity?.smartReserveAccountConfirm?.minimumInvestment || false,

      proprietaryAssets: mainAccountsEntity?.smartReserveAccountConfirm?.proprietaryAssets || false,

      operatingExpenses: mainAccountsEntity?.smartReserveAccountConfirm?.operatingExpenses || false,

      investmentObjective: mainAccountsEntity?.smartReserveAccountConfirm?.investmentObjective || false,
      // fieldConfirmationCheck: false,
    },
  });

  const handleInputChange = e => {
    const { name: checkboxName, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (checkboxName.includes('.')) {
      const [parentKey, childKey] = checkboxName.split('.');
      setInitialRequirement({
        ...initialRequirements,
        [parentKey]: {
          ...initialRequirements[parentKey],
          [childKey]: newValue,
        },
      });
      const newProp: any = { ...mainAccountsEntity[parentKey], [childKey]: newValue };
      dispatch(updateEntityProperty({ [parentKey]: newProp }));
    } else {
      setInitialRequirement({
        ...initialRequirements,
        [checkboxName]: newValue,
      });
      dispatch(updateEntityProperty({ [checkboxName]: checked }));
    }
  };

  const formatCurrency = amount => {
    if (amount === '') {
      return '';
    }

    if (isNaN(amount)) {
      return '';
    }
    const parsedAmount = parseFloat(amount);

    return parsedAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };

  const [validationErrror, setValidationError] = useState('');
  const [companyAccount, setCompanyAccount] = useState(0);
  const [investA, setInvest] = useState('');
  const handleAccountType = e => {
    setCompanyAccount(e.target.value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const numericValue = parseFloat(inputVal.replace(/[^\d.]/g, ''));

    setInitialRequirement(prevState => ({
      ...prevState,
      investmentAmount: numericValue.toString(),
    }));

    if (!isNaN(numericValue) && numericValue >= 50000) {
      setInvestError('');
    } else {
      setInvestError(numericValue ? 'Minimum value should be €50,000' : 'This field is mandatory');
    }
    dispatch(updateEntityProperty({ investmentAmount: numericValue.toString() }));

    setInvest((e.target.value) || mainAccountsEntity.investmentAmount)


  };

  useEffect(() => {
    setInvest(mainAccountsEntity.investmentAmount)

    const isValid =
      investA && !investError &&
      (initialRequirements?.EURReserve || initialRequirements?.USDReserve) &&
      initialRequirements?.smartReserveAccountConfirm?.over18 &&
      initialRequirements?.smartReserveAccountConfirm?.beneficialOwner &&
      initialRequirements?.smartReserveAccountConfirm?.residentInIreland &&
      initialRequirements?.smartReserveAccountConfirm?.investmentObjective &&

      initialRequirements?.smartReserveAccountConfirm?.proprietaryAssets &&
      initialRequirements?.smartReserveAccountConfirm?.operatingExpenses &&

      initialRequirements?.smartReserveAccountConfirm?.taxResidentAndPPS;



    setFormValid(isValid);
    onFormComplete(isValid);
  }, [initialRequirements, companyAccount, handleAmountChange]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
   
    const hasModalShown = sessionStorage.getItem('hasModalShown');

    
    if (!hasModalShown && mainAccountsEntity.last_level === 1) {
      setIsModalOpen(true);
      sessionStorage.setItem('hasModalShown', 'true');
    }

    
  }, [mainAccountsEntity]);
  return (
    <div>
      <Row>
      {mainAccountsEntity.last_level === 1 && (
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static"centered>
          <div className="modal-header">
          </div>
          <div className="modal-body">
          <img className="img-fluid logo-dashboard" src="../../../../../content/images/black_new_logo.svg" />
          <div className='modal-text pt-3'>Create a Brighter Future in 5 minutes</div>
            <div className='modal-text pt-2'> If something comes up don’t worry, you can come back at any time and complete your application.
            All data inputs will be automatically saved so you can just pick up where you left off.</div>
          </div>
          <div className="modal-footer">
              <div>
              <Button className='button-account-custom-submit'  onClick={() => { 
             
             toggleModal(); 
            }}>Get Started</Button>
            </div>
          </div>
        </Modal>
      )}
        <Col md="12" className="checkbox-header-custom mt-3 py-2">
          Account Type: <br />
          <span className="account-sub-span-custom py-4">What type of reserve account do you require (tick 1 or both)?
          </span>
        </Col>
        <Row className="ms-2">
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="EURReserve"
                  checked={initialRequirements.EURReserve}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                EUR Reserve
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements.EURReserve && <div className="text-danger">{validationErrror}</div>}
          </Col>

          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="USDReserve"
                  checked={initialRequirements.USDReserve}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                USD Reserve
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements.USDReserve && <div className="text-danger">{validationErrror}</div>}
          </Col>
        </Row>
      </Row>
      <Col md="6" className="pt-3 ">
        <FormGroup>
          <Label className="label-account-class">
            Investment Amount <br />
            <span className="account-sub-span-custom">This is the amount that you intend to invest in the Starlight Reserve Account</span>
          </Label>
          <Input
            type="tel"
            name="investmentAmount"
            placeholder="€00,000"
            value={formatCurrency(initialRequirements.investmentAmount)}
            onChange={handleAmountChange}
            inputMode="numeric"
            className="textfield-style"
          />
          {investError && (
            <div className="error-message" style={{ color: 'red' }}>
              {investError}
            </div>
          )}
        </FormGroup>
        {validationErrror && !initialRequirements.investmentAmount && <div className="text-danger">{validationErrror}</div>}
      </Col>

      <Row>
        <Col md="12" className="checkbox-header-custom mt-2">
          To Open a Starlight Smart Reserve Account, You Must Confirm That the Company:
          <br />
          <br />


        </Col>
        <Row className="ms-2">
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.over18"
                  checked={initialRequirements.smartReserveAccountConfirm.over18}
                  defaultChecked={initialRequirements.smartReserveAccountConfirm.over18}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Is registered in Ireland
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements.smartReserveAccountConfirm.over18 && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.beneficialOwner"
                  checked={initialRequirements.smartReserveAccountConfirm.beneficialOwner}
                  defaultChecked={initialRequirements.smartReserveAccountConfirm.beneficialOwner}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Is tax resident in Ireland

              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements.smartReserveAccountConfirm.beneficialOwner && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.residentInIreland"
                  checked={initialRequirements?.smartReserveAccountConfirm?.residentInIreland}
                  defaultChecked={mainAccountsEntity?.smartReserveAccountConfirm?.residentInIreland}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Will be the beneficial owner of the account
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements?.smartReserveAccountConfirm?.residentInIreland && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.taxResidentAndPPS"
                  checked={initialRequirements?.smartReserveAccountConfirm?.taxResidentAndPPS}
                  defaultChecked={mainAccountsEntity?.smartReserveAccountConfirm?.taxResidentAndPPS}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Has a lump sum available to invest
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements?.smartReserveAccountConfirm?.taxResidentAndPPS && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.operatingExpenses"
                  checked={initialRequirements?.smartReserveAccountConfirm?.operatingExpenses}
                  // defaultChecked={mainAccountsEntity?.smartReserveAccountConfirm?.investmentObjective}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Has a minimum of 3 months of operating expenses covered from other funds
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements?.smartReserveAccountConfirm?.operatingExpenses && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>


          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.proprietaryAssets"
                  checked={initialRequirements?.smartReserveAccountConfirm?.proprietaryAssets}
                  // defaultChecked={mainAccountsEntity?.smartReserveAccountConfirm?.investmentObjective}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Will only hold the company’s proprietary assets in the account and will not hold funds of its clients or passive investors
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements?.smartReserveAccountConfirm?.proprietaryAssets && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
          <Col md="12">
            <FormGroup check>
              <Label className="label-account-class" check>
                <Input
                  type="checkbox"
                  name="smartReserveAccountConfirm.investmentObjective"
                  checked={initialRequirements?.smartReserveAccountConfirm?.investmentObjective}
                  defaultChecked={mainAccountsEntity?.smartReserveAccountConfirm?.investmentObjective}
                  onChange={handleInputChange}
                  className="input-account-custom-bgnone"
                />{' '}
                Investment Objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit rate
              </Label>
            </FormGroup>
            {validationErrror && !initialRequirements?.smartReserveAccountConfirm?.investmentObjective && (
              <div className="text-danger">{validationErrror}</div>
            )}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default forwardRef(CompanyInitialRequirement);
