import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { getCountriesList, getCountryList, getEntity, getState, updateEntity, updateEntityProperty, uploadDocument } from '../accounts/accounts.reducer';
import { toast } from 'react-toastify';
import Dropzone from 'app/modules/DropZone';
import animationData from '../../../content/lottie/upload_loader.json';
import Lottie from 'lottie-react';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import { ValidatedField } from 'react-jhipster';
import CommonToast from 'app/modules/components/CommonToast';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getCustomerDetails } from '../customer/customer.reducer';
interface secondaryDetailsProp {
  onFormComplete: (completed: boolean) => void;
}

interface IValidationErrors {
  [key: string]: string;
}

export interface secondaryDetailsRef {
  syncSecondaryDetailsWithServer: () => Promise<any>;
}
const SecondaryAplicaion: React.ForwardRefRenderFunction<secondaryDetailsRef, secondaryDetailsProp> = ({ onFormComplete }: secondaryDetailsProp, ref) => {
  const dispatch = useAppDispatch();
  const MANDATORY = 'This field is mandatory';
  const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;
  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const [isUploadingThree, setIsUploadingThree] = useState(false);
  const [isUploadingFour, setIsUploadingFour] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  //new
  const [secondvalidationErrors, setsecondValidationErrors] = useState<IValidationErrors>({});
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [uploadedPassport, setUploadedPassport] = useState<string | null>(null);
  const [pps_doc, setUploadDependantPpc] = useState<string | null>(mainAccountsEntity?.joint_details?.joint_pps_doc || '');
  const [passport_doc, setUploadDependantPassport] = useState<string | null>(mainAccountsEntity?.joint_details?.joint_passport_doc || '');
  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])

  const [AccountType, setAccountType] = useState<string>(mainAccountsEntity?.accountHolderType || '');
  const [passport_expiry_date, setPassport_expiry_date] = useState(
    sessionStorage.getItem('jointpassport_expiry_date') ||
    mainAccountsEntity?.joint_details?.jointpassport_expiry_date ||
    ''
  );
  // const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.joint_details?.jointpassport_expiry_date || '');
  const [country, setCountry] = useState<any>(mainAccountsEntity?.joint_details?.countryList?.find((data) => data?.name === 'Ireland') || {});
  // const [countryOfBirth, setCountryOfBirth] = useState<any>(mainAccountsEntity?.joint_details?.jointcountryofbirth?.find((data: any) => data?.code === 'Ireland') || {});


  const [countryOfBirth, setCountryOfBirth] = useState<any>(mainAccountsEntity?.joint_details?.countryList?.find((data) => data?.name === 'Ireland') || {});

  const [jointppsnumber, setJointppsnumber] = useState<any>(mainAccountsEntity?.joint_details?.jointppsnumber || '');
  const [othertaxresidence, setOthertaxresidence] = useState<string>(mainAccountsEntity?.joint_details?.jointothertaxresidence || '');
  const [employment_status, setEmployment_status] = useState<string>(mainAccountsEntity?.joint_details?.jointemploymentstatus || 'EMPLOYED');
  const [jointisapubliclylistedcompany, setJointisapubliclylistedcompany] = useState<string>(mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany || false);

  const [townorcity, setTownorcity] = useState<string>(mainAccountsEntity?.joint_details?.jointtownorcity || '');
  const [countryList, setCountryList] = useState<string[]>([]);
  const [state_or_province, setState_or_province] = useState<string>(mainAccountsEntity?.joint_details?.jointstateorprovince || '');
  const [ppsError, setPpsError] = useState<string | null>(null);
  const [stateList, setStateList] = useState<string[]>([]);
  const [validationMessage, setValidationMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const customerDetails = useAppSelector(state => state.customer.entities);
  const [jointemployerAddress, setJointEmployerAddress] = useState({
    street: mainAccountsEntity?.joint_details?.jointemployeraddress?.street,
    city: mainAccountsEntity?.joint_details?.jointemployeraddress?.city,
    state: mainAccountsEntity?.joint_details?.jointemployeraddress?.state || "",
    country: mainAccountsEntity?.joint_details?.jointemployeraddress?.country || 'Ireland',
    postal_code: mainAccountsEntity?.joint_details?.jointemployeraddress?.postal_code

  });


  const [newppsnum, setNewPpsNum] = useState(() => {
    const savepps = JSON.parse(sessionStorage.getItem('jointppsnum'));
    return savepps || "";
  })
  // New code imlementation
  useEffect(() => {
    if (mainAccountsEntity) {
    
      const updatedProperties = {
        jointnametitle: 'Mr',
        jointstateorprovince: 'Galway',
        jointcountry: 'Ireland',
        jointissuedcountry:(inputValues.jointissuedcountry === '' || inputValues.jointissuedcountry== null)
        ? (mainAccountsEntity?.joint_details?.jointissuedcountry || 'Ireland') 
        : inputValues.jointissuedcountry,
        jointcountryofbirth:  (inputValues.jointcountryofbirth === '' || inputValues.jointcountryofbirth== null)
        ? (mainAccountsEntity?.joint_details?.jointcountryofbirth || 'Ireland') 
        : inputValues.jointcountryofbirth,
        jointcountryofcitizenship: (inputValues.jointcountryofcitizenship === '' || inputValues.jointcountryofcitizenship== null)
        ? (mainAccountsEntity?.joint_details?.jointcountryofcitizenship || 'Ireland') 
        : inputValues.jointcountryofcitizenship,
        jointemployeraddress : {
       
          street:   (inputValues?.street === '' || inputValues?.street== null)
          ? (mainAccountsEntity?.joint_details?.jointemployeraddress?.street ) 
          : inputValues.street,
          city:  (inputValues?.city === '' || inputValues?.city== null)
          ? (mainAccountsEntity?.joint_details?.jointemployeraddress?.city ) 
          : inputValues.city,
          state: (inputValues?.state === '' || inputValues?.state== null)
          ? (mainAccountsEntity?.joint_details?.jointemployeraddress?.state ) 
          : inputValues.state,
          country: (inputValues?.country === '' || inputValues?.country== null)
          ? (mainAccountsEntity?.joint_details?.jointemployeraddress?.country ||'Ireland') 
          : inputValues.country,
          postal_code: (inputValues?.postal_code === '' || inputValues?.postal_code== null)
          ? (mainAccountsEntity?.joint_details?.jointemployeraddress?.postal_code ) 
          : inputValues.postal_code,
        },
         state: '',
        jointdependents: 1,
        jointemploymentstatus: 'EMPLOYED',
        jointmaritalstatus: 'Married'
      };
      dispatch(updateEntityProperty({ ...mainAccountsEntity, joint_details: updatedProperties }));
    }
  }, [customerDetails]);


  const [inputValues, setInputValues] = useState<{ [key: string]: any }>({ ...mainAccountsEntity, ...mainAccountsEntity.joint_details, ...mainAccountsEntity?.joint_details?.jointemployeraddress } || {});

  useImperativeHandle(ref, () => ({
    syncSecondaryDetailsWithServer

  }));
  const findCountryNameByKey = (key: string | null | undefined): string => {
    const countryName: any = countryList.find((obj: any) => obj.name === key);


    return countryName ? countryName.name : '';
  };

  useEffect(() => {
    const sessionValue = sessionStorage.getItem('jointpassport_expiry_date');
    if (sessionValue) {
      setPassport_expiry_date(sessionValue);
    } else if (mainAccountsEntity?.joint_details?.jointpassport_expiry_date) {
      setPassport_expiry_date(mainAccountsEntity.joint_details.jointpassport_expiry_date);
    }
  }, [mainAccountsEntity]);
  const [isSameAddress, setIsSameAddress] = useState(() => {
    const savedIsChecked = JSON.parse(sessionStorage.getItem('ischecked'));
    return savedIsChecked || false;
  });
  useEffect(() => {
    // Update sessionStorage whenever `isSameAddress` changes
    sessionStorage.setItem('ischecked', JSON.stringify(isSameAddress));
    sessionStorage.setItem('jointppsnum', JSON.stringify(newppsnum));
    setJointppsnumber(newppsnum)
  }, [isSameAddress, newppsnum]);


  useEffect(() => {
    const accountId = mainAccountsEntity?.toString() || '';
    // inputValues.jointemploymentstatus = 'EMPLOYED'
    if (accountId) {

      dispatch(getEntity(mainAccountsEntity?.accountId));

    }

  }, []);


  const [isTaxResident, setIsTaxResident] = useState<boolean>(mainAccountsEntity?.joint_details?.jointistaxresident ?? true);
  const [is_tax_resident_in_another_country_for_secondary, setIsTaxResidentinAnotherCountry] = useState<boolean>(mainAccountsEntity?.joint_details?.is_tax_resident_in_another_country_for_secondary ?? false);

  useEffect(() => {
    dispatch(updateEntityProperty({
      is_joint_account: true,
      joint_details: {
        ...mainAccountsEntity.joint_details,
        jointistaxresident: mainAccountsEntity?.joint_details?.jointistaxresident ?? true,
      
      }

    }));
  }, [isTaxResident]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "jointistaxresident") {
      dispatch(updateEntityProperty({
        is_joint_account: true,
        joint_details: {
          ...mainAccountsEntity.joint_details,
          jointistaxresident: checked
        }

      }));
      setIsTaxResident(checked)

    }
    // Update Redux statef
    // dispatch(updateEntityProperty({
    //   is_joint_account: true,
    //   joint_details: {
    //     ...mainAccountsEntity.joint_details,
    //     jointistaxresident: checked
    //   },
    // }));
    
    if (name === "is_tax_resident_in_another_country_for_secondary") {
      dispatch(updateEntityProperty({
        is_joint_account: true,
        joint_details: {
          ...mainAccountsEntity.joint_details,
          is_tax_resident_in_another_country_for_secondary: checked
        }

      }));
      setIsTaxResidentinAnotherCountry(checked)

    }
  };
  const isValidDate = date => {
    if (!date) return false; // Handle undefined or null date
    const [year, month, day] = date.split('-').map(Number);

    // Basic validation to check if year, month, and day are valid numbers
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return false;
    }

    // Check if the month is between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Create a date object and check if the day matches (to prevent invalid dates like April 31)
    const parsedDate = new Date(year, month - 1, day);
    return parsedDate.getFullYear() === year &&
           parsedDate.getMonth() === month - 1 &&
           parsedDate.getDate() === day;
};

  const syncSecondaryDetailsWithServer = (): Promise<any> => {
    const _errors: IValidationErrors = {};
    if (!inputValues.jointaddressline1 && !isSameAddress) {
      _errors.jointaddressline1 = MANDATORY;
    }

    if (!inputValues.jointfirstname) {
      _errors.jointfirstname = MANDATORY;
    }
    if (!isValidDate(inputValues.jointdob)) {
      _errors.jointdob = 'Enter a valid date';
    } else if (!inputValues.jointdob) {
      _errors.jointdob = 'Enter a valid date.';
    } else if (!isValidYearLength(inputValues.jointdob)) {
      _errors.jointdob = 'Enter a valid four-digit year.';
    } else if (!isOver18(inputValues.jointdob)) {
      _errors.jointdob = 'You must be 18 years old or older.';
    }
    
    if (!inputValues.jointsurname) {
      _errors.jointsurname = 'This field is mandatory';
    }
    if (!inputValues.joint_username) {
      _errors.joint_username = 'This field is mandatory';
    }
    if ((!inputValues.jointmobilenumber || inputValues.jointmobilenumber === '+3538') || inputValues.jointmobilenumber.length !== 13) {
      _errors.jointmobilenumber = 'Please enter a valid mobile number starting with +3538 and up to 8 digits.';
    }
    if (!inputValues.dependendPassportNumber) {
      _errors.dependendPassportNumber = 'This field is mandatory';
    }
    if (!pps_doc) {
      _errors.pps_doc = 'This field is mandatory';
    }
    if (!passport_doc) {
      _errors.passport_doc = 'This field is mandatory';
    }
    if (!inputValues.jointtownorcity && !isSameAddress) {
      _errors.jointtownorcity = 'This field is mandatory';
    }
    // if (!inputValues.jointstateorprovince && !isSameAddress) {
    //   _errors.jointstateorprovince = 'This field is mandatory';
    // }
    if (!inputValues.jointpostcode && !isSameAddress) {
      _errors.jointpostcode = 'This field is mandatory';
    }
    if (!jointppsnumber) {
      _errors.ppsnumber = 'This field is mandatory'
    }
    if (!passport_expiry_date) {
      _errors.passport_expiry_date = 'This field is mandatory';
    }
    if (inputValues.jointemploymentstatus === "EMPLOYED" || inputValues.jointemploymentstatus === "SELF_EMPLOYED") {
      if (!inputValues.jointoccupation) {
        _errors.jointoccupation = 'This field is mandatory';
      }
      if (!inputValues.jointemployername) {
        _errors.jointemployername = 'This field is mandatory';
      }
      if (isTaxResident === false && !inputValues.jointothertaxresidence) {
        _errors.jointothertaxresidence = 'This field is mandatory';
      }
      if (!inputValues.jointemployerbusiness) {
        _errors.jointemployerbusiness = 'This field is mandatory';
      }
      if (!inputValues.city) {
        _errors.city = 'This field is mandatory';
      }
      if (!inputValues.state){
        _errors.state = 'This field is mandatory';
      }
      if (!inputValues.postal_code) {
        _errors.postal_code = 'This field is mandatory';
      }
      if (!inputValues.street) {
        _errors.street = 'This field is mandatory';
      }
    }
    if (Object.keys(_errors).length > 0) {
      setsecondValidationErrors(_errors);
      // toast.error('Please fill all the mandatory fields', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'custom-toast'
      // });

      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);

      return null;
    }


    setsecondValidationErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 4 }));
  };

  const findStateNameByKey = (key: string | null | undefined): string => {
    const stateOrProvince: any = stateList.find((obj: any) => obj.name === key);

    return stateOrProvince ? stateOrProvince.name : '';
  };



  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData = res.payload.map((item: any) => item);
        setStateList(stateData);
      }
    });
  }, [dispatch]);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {
    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());
        if (res.payload) {
          const countryData = res.payload.map((item: any) => item);
          setCountryList(countryData);
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])



  const handlePpsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setJointppsnumber(value);
    const newPPS = e.target.value;

    sessionStorage.setItem('jointppsnumber', value)

    setNewPpsNum(sessionStorage.getItem("jointppsnumber"))

    if (PPS_REGEX.test(value ? value : newppsnum)) {
      setPpsError('');
      clearValidationError('ppsnumber');

    } else {
      setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');
    }
    dispatch(updateEntityProperty({
      is_joint_account: true,
      joint_details: {
        ...mainAccountsEntity.joint_details,
        jointppsnumber: value
      }
    }));
  };


  const handleKeyDown = (event) => {
    const allowedKeys = /[0-9+\b]/;
    const value = event.target.value;

    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (value.length >= 13 && event.keyCode !== 8 && !(event.keyCode === 8)) {
      event.preventDefault();
    }

    if (event.key === '+' && value.length !== 0) {
      event.preventDefault();
    }
    if ((event.key === 'Backspace' || event.keyCode === 8) && event.target.selectionStart <= 5) {
      event.preventDefault();
    }
  };
  const [dobValidationError, setDobValidationError] = useState('');



  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    inputValues[name] = value;

    if (name === 'jointemploymentstatus') {
      setEmployment_status(value);}
    if (name !== 'joint_username' && name !== 'jointmobilenumber') {
      clearValidationError(name);
      clearValidationError('jointaddressline1');


    }


    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const regex = /^\+3538\d{0,8}$/;

    // if (PPS_REGEX.test(value)) {
    //   secondvalidationErrors.ppsnumber = '';
    // } else {
    //   secondvalidationErrors.ppsnumber = 'Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.';
    // }

    if (name === 'joint_username') {
      if (!emailPattern.test(value)) {
        secondvalidationErrors.joint_username = 'Invalid email address';

      } else {
        secondvalidationErrors.joint_username = '';
        clearValidationError('joint_username');

      }
    }
    if (name === 'jointdob') {
      if (!isValidDate(value)) {
        setDobValidationError('Enter a valid date.');
      } else if (!isOver18(value)) {
        setDobValidationError('You must be 18 years old or older.');
      } else {
        setDobValidationError('');
        clearValidationError('jointdob');
      }
    }

    if (name === 'jointmobilenumber') {
      if (value.length !== 13 || !regex.test(value)) {
        secondvalidationErrors.jointmobilenumber = 'Please enter a valid mobile number starting with +3538 and followed by 8 digits.';
      } else {
        secondvalidationErrors.jointmobilenumber = '';
        clearValidationError('jointmobilenumber');

      }
    }


    if (name.startsWith('joint') || name === 'dependendPassportNumber') {
      const newProp: any = { ...mainAccountsEntity.joint_details, [name]: value };

      dispatch(updateEntityProperty({
        is_joint_account: true,
        ['joint_details']: newProp,
      }));
    } else if (['jointnametitle', 'jointtownorcity', 'passport_expiry_date', 'jointaddressline1', 'dependendPassportNumber', 'jointfirstname', 'jointmobilenumber', 'jointsurname', 'jointstateorprovince', 'jointcountry', 'jointemploymentstatus', 'jointoccupation', 'jointemployername','jointcountryofcitizenship'].includes(name)) {
      const newProp: any = { ...mainAccountsEntity.joint_details, [name]: value };      
      dispatch(updateEntityProperty({
        is_joint_account: true,
        ['joint_details']: newProp,
      }));
    } else if (['street', 'city', 'state', 'country', 'postal_code'].includes(name)) {
      const jointemployeraddress = { ...mainAccountsEntity.joint_details?.jointemployeraddress, [name]: value };
      const newProp = { ...mainAccountsEntity.joint_details, jointemployeraddress };
      dispatch(updateEntityProperty({ is_joint_account: true, joint_details: newProp, }));
    }
    else {
      dispatch(updateEntityProperty({
        is_joint_account: true,
        [name]: value,
      }));
    }

  };
  
  useEffect(() => {
    if (isSameAddress) {
      const newProp = { ...mainAccountsEntity.joint_details, jointtownorcity: mainAccountsEntity.townOrCity, jointaddressline2: mainAccountsEntity.addressLine2, jointaddressline1: mainAccountsEntity.addressLine1, jointpostcode: mainAccountsEntity.postcode, jointstateorprovince: mainAccountsEntity.state_or_province };
      dispatch(updateEntityProperty({
        ...mainAccountsEntity.joint_details,
        is_joint_account: true,
        joint_details: newProp,
      }));
    }
  }, [isSameAddress, mainAccountsEntity.townOrCity, dispatch]);

  // const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   inputValues[name] = value;

  //   const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //   const regex = /^\+353\d{0,9}$/;
  //   const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;
  //   if (PPS_REGEX.test(value)) {
  //     secondvalidationErrors.ppsnumber = ''
  //   } else {
  //     secondvalidationErrors.ppsnumber = 'Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.';
  //   }
  //   if (name === 'joint_username' && !emailPattern.test(value)) {

  //     secondvalidationErrors.joint_username = 'Invalid email address';
  //   } else {
  //     secondvalidationErrors.joint_username = '';
  //   }
  //   if (name === 'jointmobilenumber' && value.length <= 13 && regex.test(value)) {

  //     secondvalidationErrors.jointmobilenumber = '';
  //   } else if (value.length !== 13) {
  //     secondvalidationErrors.jointmobilenumber = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
  //   } else {
  //     secondvalidationErrors.jointmobilenumber = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
  //   }

  //   inputValues[name] = value;
  //   if (name.startsWith('joint') || name === 'dependendPassportNumber') {
  //     const newProp: any = { ...mainAccountsEntity.joint_details, [name]: value }

  //     dispatch(updateEntityProperty({
  //       is_joint_account: true,
  //       ['joint_details']: newProp,
  //     }));
  //   } else if (name === 'street' || name === 'city' || name === 'state' || name === 'country' || name === 'postal_code') {
  //     const newProp: any = { ...mainAccountsEntity.employerAddress, [name]: value }

  //     dispatch(updateEntityProperty({
  //       is_joint_account: true,
  //       ['employerAddress']: newProp,
  //     }));
  //   } else {
  //     dispatch(updateEntityProperty({
  //       is_joint_account: true,
  //       [name]: value,
  //     }));
  //   }
  // };
  const clearsecondValidationError = (field: string) => {
    setsecondValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  }

  const clearValidationError = (field: string) => {
    setsecondValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  //
  useEffect(() => {
    dispatch(getCountriesList());
  }, [dispatch]);


  const handleFileDependantPpcUpload = (file: File, docType: string) => {

    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }

    if (!accountsEntity.accountId && !pendingTaskAccountId?.[0].accountId) {
      return;
    }
    localStorage.setItem('accountId', accountsEntity.accountId);
    dispatch(
      uploadDocument({
        accountId: accountsEntity.accountId || !pendingTaskAccountId?.[0].accountId,
        file,
        docType,

      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadDependantPpc(response.payload.data);
          const updatedJointDetails = { ...mainAccountsEntity.joint_details, joint_pps_doc: response.payload.data };

          // Dispatch the updated entity property
          dispatch(updateEntityProperty({
            joint_details: updatedJointDetails,
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
        }  else if (response?.payload?.response?.status===400) {
          console.error('Response payload is undefined');
          setIsUploadingThree(false);
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Failed!! Document size is more than 2MB');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingThree(false);
      })
      .catch(error => {
        setIsUploadingThree(false);
        setToastMessage((prev) => 'Failed!! Document size is more than 2MB');
        setToastType('error');
        setToastVisible(true);
        console.error(`Error uploading ${docType}:`, error);
      });
    setIsUploadingThree(true);

    setTimeout(() => {
      setIsUploadingThree(false);
    }, 1500);
  };

  const handleJointDeleteFile = () => {
    setUploadDependantPpc(null);
  };

  const handleDependantPassportFileUpload = (file: File, docType: string) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }

    dispatch(
      uploadDocument({
        accountId: accountsEntity.accountId || !pendingTaskAccountId?.[0].accountId,
        file,
        docType,
      }),
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadDependantPassport(response.payload.data);
          const updatedJointDetails = { ...mainAccountsEntity.joint_details, joint_passport_doc: response.payload.data };

          // Dispatch the updated entity property
          dispatch(updateEntityProperty({
            joint_details: updatedJointDetails,
          }));
          // toast.success('Uploaded successfully', {
          //   className: 'custom-toast'
          //   ,
          //   position: toast.POSITION.TOP_CENTER,
          // });
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
        } else if (response?.payload?.response?.status===400){
          console.error('Response payload is undefined');
          setIsUploadingFour(false);
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Failed!! Document size is more than 2MB');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingFour(false);
      })
      .catch(error => {
        console.error(`Error uploading ${docType}:`, error);
        setIsUploadingFour(false);
          setToastMessage((prev) => 'Failed!! Document size is more than 2MB');
          setToastType('error');
          setToastVisible(true);
      });
    setIsUploadingFour(true);

    setTimeout(() => {
      setIsUploadingFour(false);
    }, 1500);
  };

  const handleJointDeletePassportFile = () => {
    setUploadDependantPassport(null);
  };

  const formatCurrency = (amount: number | string) => {
    if (amount === '') {
      return '';
    }

    if (isNaN(Number(amount))) {
      return '';
    }

    const parsedAmount = parseFloat(amount.toString());

    return parsedAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };
  const isOver18 = dob => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 >= 18;
    }
    return age >= 18;
  };
  const isValidYearLength = (date) => {
    if (!date) return true;
    const year = new Date(date).getFullYear().toString();
    return year.length === 4;
  };

  const handleAddressResidentChange = (e) => {
    const isChecked = e.target.checked;
    setIsSameAddress(isChecked);
    let updatedProperties;

    if (isChecked) {
      updatedProperties = {
        jointaddressline1: mainAccountsEntity?.addressLine1,
        jointaddressline2: mainAccountsEntity?.addressLine2,
        jointstateorprovince: state_or_province,
        jointtownorcity: townorcity,
        jointcountry: country,
        jointpostcode: mainAccountsEntity?.postcode,
        isSameAddress: isChecked,
      };
    } else {
      updatedProperties = {
        jointaddressline1: '',
        jointaddressline2: '',
        jointstateorprovince: '',
        jointtownorcity: '',
        jointcountry: '',
        jointpostcode: '',
        isSameAddress: isChecked,
      };
      inputValues.jointaddressline1 = "";
      inputValues.jointaddressline2 = "";
      inputValues.jointtownorcity = "";
      inputValues.jointpostcode = "";
      


    }
    clearValidationError('jointaddressline1');
    clearValidationError('jointtownorcity');
    clearValidationError('jointpostcode');
    dispatch(updateEntityProperty({
      ...mainAccountsEntity,
      ...updatedProperties,
    }));
  };


  const handleJointPassportExpiry = (e: any) => {
    const lcl_passport_expiry_date = e.target.value;

    setPassport_expiry_date(lcl_passport_expiry_date)
    sessionStorage.setItem('jointpassport_expiry_date', lcl_passport_expiry_date);
    clearValidationError('passport_expiry_date');
    dispatch(updateEntityProperty({
      is_joint_account: true,
      joint_details: {
        ...mainAccountsEntity.joint_details,
        jointpassport_expiry_date: lcl_passport_expiry_date
      }
    }));

  }

  const [minDate, setMinDate] = useState('');
  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);
  const getFifteenDaysFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);
    return today.toISOString().split('T')[0];
  };
  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);


  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <Row>
        <Col md="12" className="form-firstrow-account-head-custom pt-3 pb-3">
          Secondary Applicant Details
        </Col>

        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}></span>
                <span>Title</span>
              </Label>

              <Input
                type="select"
                name="jointnametitle"
                id="jointnametitle"
                value={inputValues.jointnametitle}
                defaultValue={mainAccountsEntity?.joint_details?.jointnametitle || 'Mr'}
                onChange={handleValueChange}
                className="textfield-style"
              >
                <option>Mr</option>
                <option>Mrs</option>
                <option>Ms</option>
                <option>Dr</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>First Name</span>
              </Label>
              <Input
                type="text"
                name="jointfirstname"
                id="jointfirstname"
                value={inputValues.jointfirstname}
                defaultValue={mainAccountsEntity?.joint_details?.jointfirstname}
                onChange={e => handleValueChange(e)}
                className="textfield-style-read-only"
              />

              {secondvalidationErrors.jointfirstname && <div className="text-danger">{secondvalidationErrors.jointfirstname}</div>}{' '}
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Last Name</span>
              </Label>
              <Input
                type="text"
                name="jointsurname"
                id="jointsurname"
                value={inputValues.jointsurname}
                defaultValue={mainAccountsEntity?.joint_details?.jointsurname}
                onChange={e => handleValueChange(e)}
                className="textfield-style-read-only"
              />
              {secondvalidationErrors.jointsurname && <div className="text-danger">{secondvalidationErrors.jointsurname}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}></span>
                <span>Middle Name</span>
              </Label>
              <Input
                className="textfield-style"
                type="text"
                name="jointmiddleName"
                id="jointmiddleName"
                required
                value={inputValues.jointmiddleName}
                defaultValue={mainAccountsEntity.joint_details?.jointmiddleName}
                onChange={e => handleValueChange(e)}
                placeholder="enter your name"
              />

              <span className="text-black">Please provide your middle name if it is included in your passport</span>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Your Email</span>
              </Label>
              <Input
                className="textfield-style-read-only"
                type="email"
                name="joint_username"
                required
                id="joint_username"
                value={inputValues.joint_username}
                defaultValue={mainAccountsEntity?.joint_details?.joint_username}
                onChange={e => handleValueChange(e)}
                placeholder="enter your email"
              />
              {validationMessage && <div className="text-danger">{validationMessage}</div>}{' '}
              {/* {complianceOfficerValidationEmailError && <div className="text-danger">{complianceOfficerValidationEmailError}</div>}{' '} */}
              {secondvalidationErrors.joint_username && <div className="text-danger">{secondvalidationErrors.joint_username}</div>}{' '}
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Phone Number</span>
              </Label>
              <Input
                type="tel"
                name="jointmobilenumber"
                id="jointmobilenumber"
                contentEditable={true}
                value={inputValues.jointmobilenumber}
                defaultValue={mainAccountsEntity?.joint_details?.jointmobilenumber || '+3538'}
                onChange={e => handleValueChange(e)}
                className="textfield-style"
                onKeyDown={handleKeyDown}
              />
              {validationMessage && <div className="text-danger">{validationMessage}</div>}{' '}

              {secondvalidationErrors.jointmobilenumber && <div className="text-danger">{secondvalidationErrors.jointmobilenumber}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Marital Status </span>
              </Label>

              <Input
                type="select"
                name="jointmaritalstatus"
                id="jointmaritalstatus"
                value={inputValues.jointmaritalstatus}
                defaultValue={mainAccountsEntity?.joint_details?.jointmaritalstatus}
                className="textfield-style"
                onChange={e => handleValueChange(e)}
              >
                <option value={'Married'}>Married</option>
                <option value={'Single'}>Single</option>
              </Input>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Dependents</span>
              </Label>

              <Input
                type="select"
                name="jointdependents"
                id="jointdependents"
                className="textfield-style"
                value={inputValues.jointdependents}
                defaultValue={mainAccountsEntity?.joint_details?.jointdependents}
                onChange={e => handleValueChange(e)}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Account Type </span>
              </Label>

              <div className='pt-3'>
                <Input
                  type="text"
                  name="accountHolderType"
                  id="accountHolderType"
                  className="textfield-style-read-only "
                  value={inputValues?.accountHolderType}
                  defaultValue={mainAccountsEntity?.accountHolderType}
                  readOnly
                  onChange={e => setAccountType(e.target.value)}
                />
              </div>
              {secondvalidationErrors && AccountType === '' && <p className="text-danger">{secondvalidationErrors?.AccountType}</p>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Date of Birth</span>
              </Label>

              <Form className="pt-3">
  <ValidatedField
    name="jointdob"
    required
    className="datefield-expiry-style"
    type="date"
    data-cy="jointdob"
    value={inputValues.jointdob}
    defaultValue={mainAccountsEntity?.joint_details?.jointdob}
    onChange={e => handleValueChange(e)}

    validate={{
      required: 'Date of birth cannot be empty!',
      validate: value => {
        if (!isValidDate(value)) {
          return 'Enter a valid date.';
        } else if (!isValidYearLength(value)) {
          return 'Enter a valid four-digit year.';
        } else if (!isOver18(value)) {
          return 'You must be 18 years old or older.';
        }
        return true;
      },
    }}
  />
   {!dobValidationError&&secondvalidationErrors.jointdob && (
    <div className="text-danger">{secondvalidationErrors.jointdob}</div>
  )}
  {dobValidationError && (
  <div className="text-danger">{dobValidationError}</div>  )}
</Form>
            </FormGroup>
          </Col>

          {/* <Col md="6">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Investment Amount</span>
              </Label>
              <Input
                type="text"
                name="investmentAmount"
                id="investmentAmount"
                placeholder="Enter your amount"
                className="textfield-style"
                inputMode="numeric"
                value={formatCurrency(jointinvestAmount)}
                onChange={handlejointInputChange}
              />
              {secondvalidationErrors.jointinvestAmount && <div className="text-danger">{secondvalidationErrors.jointinvestAmount}</div>}{' '}
            </FormGroup>
          </Col> */}
        </Row>
        <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <Col md="12">
              <Label check className="label-account-class">
                <Input
                  type="checkbox"
                  name="jointistaxresident"
                  id="jointistaxresident"
                  className="select-box"
                  checked={isSameAddress}
                  onChange={e => handleAddressResidentChange(e)}
                />
                &nbsp;Confirm that you live at the same address as the Primary Applicant
              </Label>
            </Col>
          </FormGroup>
        </Row>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>House Street Address</span>
              </Label>
              <Input
                type="text"
                name="jointaddressline1"
                id="jointaddressline1"
                value={isSameAddress ? mainAccountsEntity?.addressLine1 : inputValues?.jointaddressline1}
                defaultValue={isSameAddress ? mainAccountsEntity?.addressLine1 : mainAccountsEntity?.joint_details?.jointaddressline1}
                onChange={e => handleValueChange(e)}
                className="textfield-style"
              />
              {secondvalidationErrors?.jointaddressline1 &&!isSameAddress} <div className="text-danger">{secondvalidationErrors?.jointaddressline1}</div>{' '}

            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}></span>Address Line 2
              </Label>
              <Input
                type="text"
                name="jointaddressline2"
                id="jointaddressline2"
                value={isSameAddress ? mainAccountsEntity.addressLine2 : inputValues.jointaddressline2}
                defaultValue={isSameAddress ? mainAccountsEntity.addressLine2 : mainAccountsEntity.joint_details?.jointaddressline2}
                onChange={e => handleValueChange(e)}
                className="textfield-style"
              />
            </FormGroup>
          </Col>


          <Col md="4">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Town / City</span>
              </Label>

              <Input
                type="text"
                name="jointtownorcity"
                id="jointtownorcity"
                className="textfield-style"
                value={isSameAddress ? mainAccountsEntity.townOrCity : inputValues.jointtownorcity}
                defaultValue={isSameAddress ? mainAccountsEntity.townOrCity : mainAccountsEntity.joint_details?.jointtownorcity}
                onChange={e => handleValueChange(e)}
              />
              {secondvalidationErrors.jointtownorcity && <div className="text-danger">{secondvalidationErrors.jointtownorcity}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>County
              </Label>
              <Input
                type="select"
                name="jointstateorprovince"
                id="jointstateorprovince"
                className="textfield-style"
                // value={!isSameAddress ? mainAccountsEntity.state_or_province : inputValues.jointstateorprovince}
                // readOnly
                // value={isSameAddress ? mainAccountsEntity.state_or_province : inputValues.jointstateorprovince}
                value={isSameAddress ? mainAccountsEntity.state_or_province : inputValues.jointstateorprovince}
                onChange={e => handleValueChange(e)}
              >
                 <> <option value=""></option></>
                {stateList && stateList.map((item: any, index) => (
                  <option key={index} value={item?.name}>{item?.name}</option>
                ))}
              </Input>
              {secondvalidationErrors.jointstateorprovince && <div className="text-danger">{secondvalidationErrors.jointstateorprovince}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Country</Label>
              <Input
                type='text'
                name="jointcountry"
                id="jointcountry"

                className="textfield-style"
                value={isSameAddress ? mainAccountsEntity?.country : inputValues.jointcountry || "Ireland"}
                defaultValue={isSameAddress ? mainAccountsEntity?.country : mainAccountsEntity?.joint_details?.jointcountry}
                onChange={e => handleValueChange(e)}
                // readOnly={isSameAddress}
                readOnly
              >
                {/* <> <option value={countryOfBirth.name}>{countryOfBirth.name || findCountryNameByKey("Ireland")}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))} */}

              </Input>

            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Post Code</Label>
              <Input
                type="text"
                name="jointpostcode"
                id="jointpostcode"
                className="textfield-style"
                value={isSameAddress ? mainAccountsEntity.postcode : inputValues.jointpostcode}
                defaultValue={isSameAddress ? mainAccountsEntity.postcode : mainAccountsEntity.joint_details?.jointpostcode}
                onChange={e => handleValueChange(e)}
              />
              {secondvalidationErrors.jointpostcode && <div className="text-danger">{secondvalidationErrors.jointpostcode}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Secondary Applicant PPS Number </span>
              </Label>
              <div className="pt-4 mt-2">
                {/* <Input
                  type="text"
                  name="jointppsnumber"
                  id="jointppsnumber"
                  value={jointppsnumber}
                  defaultValue={mainAccountsEntity?.joint_details?.jointppsnumber}
                  onChange={e => handlePpsNumberChange(e)}
                  className="textfield-style"
                /> */}
                <Input
                  type="text"
                  name="jointppsnumber"
                  id="jointppsnumber"
                  value={jointppsnumber}

                  defaultValue={mainAccountsEntity?.joint_details?.jointppsnumber || newppsnum}
                  onChange={e => handlePpsNumberChange(e)}
                  className="textfield-style"
                />

                {ppsError && <div className="error-message" style={{ color: 'red' }}>{ppsError}</div>}

                {secondvalidationErrors.ppsnumber && <div className="text-danger">{secondvalidationErrors.ppsnumber}</div>}{' '}

              </div>
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <Row>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Proof of Address Upload</span>
              </Label>

              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isUploadingThree ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {' '}
                    {pps_doc ? (
                      <div className=" textfield-style-upload">
                        <ul style={{ listStyleType: 'none' }}>
                          <li className="text-light iconxs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span className="text-danger cursor-pointer ms-2 strong gap-5">
                                <FileEarmarkMedicalFill className="text-primary" style={{ fontSize: '40px' }} /> <span> &nbsp;</span>{' '}
                                <span className="text-primary"> {'PPC'} </span>
                              </span>
                              <div className="trash-btn" onClick={handleJointDeleteFile} style={{ cursor: 'pointer' }}>
                                <Trash className="trash-btn" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <span className="text-black">
                          Please upload a recent bank statement or utility bill in PNG, JPG, JPEG, or PDF file format. This should match the above address details.
                        </span>
                        <Dropzone onFileUploaded={handleFileDependantPpcUpload} docType={'PPC'} />
                        {secondvalidationErrors.pps_doc && (
                          <div className="text-danger">{secondvalidationErrors.pps_doc}</div>
                        )}{' '}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Row>
          </Col>
          <Col md="6 " className="py-3">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Secondary Applicant Passport Number</span>
              </Label>
              <Input
                type="text"
                name="dependendPassportNumber"
                id="dependendPassportNumber"
                className="textfield-style"
                value={inputValues.dependendPassportNumber}
                defaultValue={mainAccountsEntity?.joint_details?.dependendPassportNumber}
                onChange={e => handleValueChange(e)}
              />
              {secondvalidationErrors.dependendPassportNumber && (
                <div className="text-danger">{secondvalidationErrors.dependendPassportNumber}</div>
              )}{' '}
            </FormGroup>
          </Col>
          <Col md="6" className="py-3">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Country Issued
              </Label>
              <Input
                type="select"
                name="jointissuedcountry"
                id="jointissuedcountry"
                className="textfield-style"
                value={inputValues.jointissuedcountry}
                defaultValue={mainAccountsEntity?.joint_details?.jointissuedcountry || "Ireland"}
                onChange={handleValueChange}
              >
                <> <option value={mainAccountsEntity?.joint_details?.jointissuedcountry || inputValues.jointissuedcountry}>{mainAccountsEntity?.joint_details?.jointissuedcountry || inputValues.jointissuedcountry || "Ireland"}</option></>
                {countryList && countryList.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
              {/* {validationError && !secondaryform.issued_country && <div className="text-danger">{validationError}</div>} */}
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Passport Expiry Date</span>
              </Label>

              {/* <Form className="pt-3">
                <ValidatedField
                  name="jointpassport_expiry_date"
                  required
                  className="datefield-expiry-style"
                  type="date"
                  data-cy="date of birth"
                  value={inputValues.jointpassport_expiry_date}
                  defaultValue={mainAccountsEntity?.joint_details?.jointpassport_expiry_date}
                  onChange={e => handleJointPassportExpiry(e)}
                  min={minDate}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ width: '100%', padding: '3px 12px', backgroundImage: 'none' }}
                />
                {secondvalidationErrors.jointpassport_expiry_date && <div className="text-danger">{secondvalidationErrors.jointpassport_expiry_date}</div>}{' '}

              </Form> */}

              <Form className="pt-3">
                <ValidatedField
                  name="jointpassport_expiry_date"
                  required
                  className="datefield-expiry-style"
                  type="date"
                  data-cy="date of birth"
                  value={inputValues.jointpassport_expiry_date || passport_expiry_date}
                  defaultValue={mainAccountsEntity?.joint_details?.jointpassport_expiry_date}
                  onChange={e => handleJointPassportExpiry(e)}
                  min={minDate}
                  // onKeyDown={(e) => e.preventDefault()}
                  style={{ width: '100%', padding: '3px 12px', backgroundImage: 'none' }}
                />
                <span className="text-black">
                  Please select from calender
                </span>
                {secondvalidationErrors.passport_expiry_date && <div className="text-danger">{secondvalidationErrors.passport_expiry_date}</div>}{' '}

              </Form>

            </FormGroup>
          </Col>
          <Col md="6">
            <Row>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Passport Document Upload</span>
              </Label>

              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {isUploadingFour ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {passport_doc ? (
                      <div className=" textfield-style-upload">
                        <ul style={{ listStyleType: 'none' }}>
                          <li className="text-light iconxs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span className="text-danger cursor-pointer ms-2 strong">
                                <FileEarmarkMedicalFill className="text-primary" style={{ fontSize: '40px' }} />
                                <span>&nbsp;</span>
                                <span className="text-primary">{'PASSPORT'}</span>
                              </span>
                              <div className="trash-btn" onClick={handleJointDeletePassportFile} style={{ cursor: 'pointer' }}>
                                <Trash className="trash-btn" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <span className="text-black">
                          Please upload a recent passport document in PNG, JPG, JPEG, or PDF file format. This should include the main page and the signature page, which must be signed.
                        </span>
                        <Dropzone onFileUploaded={handleDependantPassportFileUpload} docType={'PASSPORT'} />
                        {secondvalidationErrors.passport_doc && (
                          <div className="text-danger">{secondvalidationErrors.passport_doc}</div>
                        )}{' '}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Row>
          </Col>
          <Col md="6" className="py-3">
            <FormGroup>
              <Label className="label-account-class">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>Country of Birth
              </Label>
              <Input
                type="select"
                name="jointcountryofbirth"
                id="jointcountryofbirth"
                className="textfield-style"
                value={inputValues.jointcountryofbirth}
                defaultValue={mainAccountsEntity?.joint_details?.jointcountryofbirth || 'Ireland'}
                onChange={e => handleValueChange(e)}
              >

                <> <option value={mainAccountsEntity?.joint_details?.jointcountryofbirth}>{mainAccountsEntity?.joint_details?.jointcountryofbirth || "Ireland"}</option></>
                {countryList && countryList.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
                {/* {countryList && countryList.map((item: any, index) => (
                  <option defaultValue={'Ireland'} key={index} value={item.code}>{item.name}</option> // Ensure to use appropriate properties
                ))} */}
              </Input>

              {/* {validationError && !secondaryform.issued_country && <div className="text-danger">{validationError}</div>} */}
            </FormGroup>
          </Col>

          <Col md="6 pt-3">
            <FormGroup>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">Country of Citizenship</Label>
              <Input
                type="select"
                name="jointcountryofcitizenship"
                id="jointcountryofcitizenship"
                className="textfield-style-read-only"
                value={inputValues.jointcountryofcitizenship}
                defaultValue={mainAccountsEntity?.joint_details?.jointcountryofcitizenship || 'Ireland'}
                onChange={e => handleValueChange(e)}
              >
                <> <option value={mainAccountsEntity?.joint_details?.jointcountryofcitizenship}>{mainAccountsEntity?.joint_details?.jointcountryofcitizenship || "Ireland"}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>

        </Row>
        <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
          <FormGroup>
            <Label className="label-account-class">Your tax status</Label></FormGroup>
        </Row>
        <Col md="12 py-2 ">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="jointistaxresident"
                id="jointistaxresident"
                className="select-box"
                checked={isTaxResident}
                // defaultValue={mainAccountsEntity?.joint_details?.jointistaxresident}
                onChange={handleCheckboxChange}
              />
            I am only tax resident in Ireland
            </Label>
          </FormGroup>
        </Col>
        <Col md="12 py-2 ">
          <FormGroup check>
            <Label check className="label-account-class">
              <Input
                type="checkbox"
                name="is_tax_resident_in_another_country_for_secondary"
                id="is_tax_resident_in_another_country_for_secondary"
                className="select-box"
                checked={is_tax_resident_in_another_country_for_secondary}
                // defaultValue={mainAccountsEntity?.joint_details?.jointistaxresident}
                onChange={handleCheckboxChange}
              />
            I am also a tax resident in another country
            </Label>
          </FormGroup>
        </Col>
        {is_tax_resident_in_another_country_for_secondary === true ? (
          <Col md="12">
            <FormGroup>
              <Label className="label-account-class">
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Other Tax Residence Details</span>
                </Label>
              <Input
                type="text"
                name="jointothertaxresidence"
                id="jointothertaxresidence"
                defaultValue={mainAccountsEntity?.joint_details?.jointothertaxresidence || ''}
                className="textfield-style"
                onChange={handleValueChange}
              />
              <br />
              <span className="text-black">
                Please list any other countries where you are tax resident, including your Tax Identification Number for that country e.g. United Kingdom
              </span>
              {secondvalidationErrors?.jointothertaxresidence && <div className="text-danger">{secondvalidationErrors?.jointothertaxresidence}</div>}
            </FormGroup>
          </Col>
        ) : null}
        <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Row>
        <Col md="6">
          <FormGroup>
            <Label className="label-account-class">
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <span>Employment Status</span>
            </Label>

            <Input
              type="select"
              name="jointemploymentstatus"
              id="jointemploymentstatus"
              value={employment_status}
              onChange={handleValueChange}
              className="textfield-style"
            >

              <option value={"EMPLOYED"}>Employed</option>
              <option value={"SELFEMPLOYED"}>Self Employed</option>
              <option value={"UNEMPLOYED"}>Unemployed</option>
              <option value={"RETIRED"}>Retired</option>
              <option value={"STUDENT"}>Student</option>
              <option value={"HOMEMAKER"}>Home Maker</option>
            </Input>
          </FormGroup>
        </Col>
        {employment_status === 'EMPLOYED' || employment_status === 'SELFEMPLOYED' ? (
          <>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Occupation</span>
                </Label>
                <Input
                  type="text"
                  name="jointoccupation"
                  value={inputValues.jointoccupation}
                  defaultValue={mainAccountsEntity.joint_details?.jointoccupation}
                  onChange={e => handleValueChange(e)}
                  id="jointoccupation"
                  className="textfield-style"
                />
                {secondvalidationErrors.jointoccupation && <div className="text-danger">{secondvalidationErrors.jointoccupation}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Employer Name</span>
                </Label>
                <Input
                  type="text"
                  name="jointemployername"
                  value={inputValues.jointemployername}
                  defaultValue={mainAccountsEntity.joint_details?.jointemployername}
                  onChange={e => handleValueChange(e)}
                  id="jointemployername"
                  className="textfield-style"
                />
                {secondvalidationErrors.jointemployername && <div className="text-danger">{secondvalidationErrors.jointemployername}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Employer Business</span>
                </Label>
                <Input
                  type="text"
                  name="jointemployerbusiness"
                  id="jointemployerbusiness"
                  value={inputValues.jointemployerbusiness}
                  defaultValue={mainAccountsEntity.joint_details?.jointemployerbusiness}
                  onChange={e => handleValueChange(e)}
                  className="textfield-style"
                />
                {secondvalidationErrors.jointemployerbusiness && <div className="text-danger">{secondvalidationErrors.jointemployerbusiness}</div>}{' '}


              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label className="label-account-class">
                  <span>Employer Address</span>
                </Label>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Street</span>
                </Label>
                <Input
                  type="text"
                  name="street"
                  value={inputValues.street}
                  defaultValue={jointemployerAddress?.street}
                  onChange={e => handleValueChange(e)}
                  id="street"
                  className="textfield-style"
                />
                {secondvalidationErrors.street && <div className="text-danger">{secondvalidationErrors.street}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>City</span>
                </Label>
                <Input
                  type="text"
                  name="city"
                  value={inputValues.city}
                  defaultValue={jointemployerAddress?.city}
                  onChange={e => handleValueChange(e)}
                  id="city"
                  className="textfield-style"
                />
                {secondvalidationErrors.city && <div className="text-danger">{secondvalidationErrors.city}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>County</span>
                </Label>
                <Input
                  type="select"
                  name="state"
                  value={inputValues.state}
                  defaultValue={jointemployerAddress?.state}
                  onChange={e => handleValueChange(e)}
                  id="state"
                  className="textfield-style"
                >
                  <> <option value=""></option></>
                {stateList && stateList.map((item: any, index) => (
                  <option key={index} value={item?.name}>{item?.name}</option>
                ))}
                </Input>
                {secondvalidationErrors.state && <div className="text-danger">{secondvalidationErrors.state}</div>}{' '}

              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Country</span>
                </Label>
                <Input
                  type="select"
                  name="country"
                  value={inputValues.country || 'Ireland'}
                  defaultValue={jointemployerAddress?.country || 'Ireland'}
                  onChange={e => handleValueChange(e)}
                  id="country"
                  className="textfield-style"
                >
                  {countryList && countryList.map((item: any, index) => (
                    <option defaultValue={'Ireland'} key={index} value={item.name}>{item.name}</option>
                  ))}
                </Input>
                {secondvalidationErrors.employerAddressCountry && <div className="text-danger">{secondvalidationErrors.employerAddressCountry}</div>}{' '}
              </FormGroup>
            </Col>


            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Post Code</span>
                </Label>
                <Input
                  type="text"
                  name="postal_code"
                  value={inputValues.postal_code}
                  defaultValue={jointemployerAddress?.postal_code}
                  onChange={e => handleValueChange(e)}
                  id="postcode"
                  className="textfield-style"
                />
                {secondvalidationErrors.postal_code && <div className="text-danger">{secondvalidationErrors.postal_code}</div>}{' '}

              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup>
                <Label className="label-account-class">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Is the Employer a Publicly Listed Company?</span>
                </Label>
                <Input
                  type="select"
                  name="jointisapubliclylistedcompany"
                  value={inputValues.jointisapubliclylistedcompany}
                  defaultChecked={mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany === "false"}
                  id="jointisapubliclylistedcompany"
                  className="textfield-style"
                  onChange={e => handleValueChange(e)}
                >
                  {secondvalidationErrors?.jointisapubliclylistedcompany && <div className="text-danger">{secondvalidationErrors?.jointisapubliclylistedcompany}</div>}{' '}

                  <option value={'false'}>No</option>
                  <option value={'true'}>Yes</option>
                </Input>
              </FormGroup>

              {mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany === "true" ||mainAccountsEntity?.jointemployerCompany ?(
                <FormGroup>
                  <Label className="label-account-class">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <span>Employer Company Stock Ticker Symbol</span>
                  </Label>
                  <Input
                    type="text"
                    name="jointemployerCompany"
                    id="jointemployerCompany"
                    value={inputValues.jointemployerCompany}
                    defaultValue={mainAccountsEntity?.joint_details?.jointemployerCompany}
                    className="textfield-style"
                    onChange={e => handleValueChange(e)}
                  />
                </FormGroup>
              ) : null}
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};
export default forwardRef(SecondaryAplicaion);



