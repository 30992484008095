/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {getBankList, getCountryList, getEntity, getState, reset, signature, submitW8BenForm, updateEntity } from './accounts.reducer';
import './style_account.css';
import './account.scss';
import { getCustomerDetails } from '../customer/customer.reducer';
import { SecondDoc } from './SecondDoc';
import FormDoc from './formDoc';
import SosDoc from './SosDoc';
import PrimaryApplication from './primary-Application';
import Pep from './pep';
import SecondaryAplicaion from './secondary-Application';
import 'react-toastify/dist/ReactToastify.css';
import InteractiveBrokers from './interactive-brokers';
import AccountSummary from './account-summary';
import W8benSecondary from './w8ben_secondary';
import CommonToast from 'app/modules/components/CommonToast';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { saveInitialPath } from 'app/modules/account/register/register.reducer';
import NetWorthAnnualIncome from './net-worth-annual-income';
import InitialRequirementCheck from './initial-requirement-check';
import AntiMoney from './anti-money';
import FinancialServices from './financial-Services';
import { PROGRESS_PERCENTAGE } from 'app/config/constants';
export const Accounts: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isAllCheckBoxesSelected, setIsAllCheckBoxesSelected] = useState(false);
  const handleCheckBoxesChange = (checkboxesSelected: boolean) => {
    setIsAllCheckBoxesSelected(checkboxesSelected);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const accountsEntity = useAppSelector(state => state.accounts.entity.accountId);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const customerDetails = useAppSelector(state => state.customer.entities);
  const [docConfirmation, setDocConfirmation] = useState({
    statement: false,
  });
  const topSectionHolder = document.querySelector('#topSectionHolder');
  const [docConfirmation3, setDocConfirmation3] = useState({
    statement3: false,
  });

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [customerId, setCustomerId] = useState()
  const [showModal, setShowModal] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [accountIdNum, setAccountId] = useState('');
  const [AccountType, setAccountType] = useState(signatureData?.account_type || 'Individual');
  const [investmentAmount, setInvestAmount] = useState(0);
  const [totalValueError, setTotalValueError] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [middle_name, setMiddleName] = useState('');
  const [country, setCountry] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [secondvalidationErrors, setsecondValidationErrors] = useState<IValidationErrors>({});
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [showdivprogress, setShowdivprogress] = useState(0);
  const [showdivper, setShowdivper] = useState(0);

  const scrollToTop = () => {
    setTimeout(() => {
      if (topSectionHolder) {
        topSectionHolder.scrollIntoView({ behavior: 'smooth' });
      }
    }, 50);
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      // navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/user-profile') {
          navigate('/user-profile')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) { /* empty */ }
      }
    }
  }, []);
  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');
    }
  }, [customerDetails]);


  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])

  const toggle = () => {
    setOpen(value => !value);
    navigate('/');
  };

  const toggle2 = () => {
    setOpen2(value2 => !value2);
  };
  const toggle3 = () => {
    setOpen3(value3 => !value3);
  };
  const toggle4 = () => {
    setOpen4(value4 => !value4);
  };
  const toggle5 = () => {
    setOpen5(value5 => !value5);
  };
  const toggle6 = () => {
    setOpen6(value6 => !value6);
  };
  const toggle7 = () => {
    setOpen7(value7 => !value7);
  };
  const [employment_status, setEmploymentStatus] = useState('EMPLOYED');

  const [FormData2, setFormData2] = useState({
    nametitle: 'Mr',
    firstname: '',
    surname: '',
    middleName: '',
    middleInitial: '',
    maritalstatus: 'Married',
    dependents: 1,
    mobilenumber: '',
    addressLine1: '',
    addressLine2: '',
    townorcity: '',
    country: 'Ireland',
    postcode: '',
    ppsnumber: '',
    passportNumber: '',
    istaxresident: false,
    othertaxresidence: '',
    employment_status: 'EMPLOYED',
    occupation: '',
    employername: '',
    employerbusiness: '',
    employerAddress: {
      country: 'Ireland',
      state: 'Galway',
      city: "",
      postal_code: "",
      street: "",
    },
    isapubliclylistedcompany: 'false',
    employeeDocument: '',
    employerCompany: '',
    passport_number: '',
    passport_expiry_date: '',
    issued_country: 'Ireland',
    state_or_province: 'Galway',
  });

  const [newcitizen, setnewcitizen] = useState({
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    stateOrProvince: 'Galway',
    zipOrPostalCode: '',
    birth_info: {
      countryOfBirth: 'Ireland',
    },
    country: 'Ireland',
  });
  const [formData4, setFormData4] = useState({
    inverstmentfromemploymentincome: '',
    invertmentfrompropertygains: '',
    inverstmentfromgifts: '',
    inverstmentfrominverstmentgains: '',
    inverstmentfromothers: '',
    inverstmentfromothersinfo: '',
  });
  const [FormDataNetworth, setFormDataWorth] = useState({
    totalnetworth: '',
    liquidnetworth: '',
    totalnetincome: '',
  });
  const [FormDataFamily, setFormDataFamily] = useState({
    isPep: false,
    familyPep: false,
    isFamilyPep: false,
    familymemberpepdetails: '',
  });
  const [FormDataFamliyFinance, setFormDataFamliyFinance] = useState({
    employedfinancialservicefirm: false,
    whoemployedfinancialservicefirm: '',
    familymemberrealtionship: '',
    complianceofficername: '',
    complianceofficercontactnumber: '+353',
    complianceofficeremail: '',
  });

  const [interactive, setInteractive] = useState({

    ib_customer_agreement: false,
    ib_client_financial_advisor: false,
    elt: false,
    ibsd_notification: false,
    lla: false,
  })

  const [secondaryform, setsecondaryform] = useState({
    jointnametitle: 'Mr',
    jointfirstname: '',
    jointemail: '',
    jointsurname: '',
    jointmiddleName: '',
    jointdob: '',
    jointmaritalstatus: 'Married',
    jointdependents: 1,
    joint_username: '',
    jointmobilenumber: '+353',
    jointaddressline1: '',
    jointaddressline2: '',
    jointtownorcity: '',
    jointcountry: 'Ireland',
    jointpostcode: '',
    jointppsnumber: '',
    dependendPassportNumber: '',
    jointistaxresident: false,
    jointothertaxresidence: '',
    jointemploymentstatus: 'EMPLOYED',
    jointoccupation: '',
    jointemployername: '',
    jointemployerbusiness: '',
    jointemployeraddress: {
      country: "Ireland",
      state: 'Galway',
      city: '',
      postal_code: '',
      street: "",
    },
    jointpassport_expiry_date: '',
    jointisapubliclylistedcompany: 'false',
    employeeDocument: '',
    jointemployerCompany: '',
    jointissuedcountry: 'Ireland',
    jointstateorprovince: "Galway",
    jointcountryofbirth: 'Ireland',
  });

  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [uploadedPassport, setUploadedPassport] = useState<string | null>(null);
  const [joint_pps_doc, setUploadDependantPpc] = useState<string | null>(null);
  const [joint_passport_doc, setUploadDependantPassport] = useState<string | null>(null);

  useEffect(() => {
    const accountId = accountsEntity?.toString() || '';

    if (accountId) {
      dispatch(getEntity(accountId))
        .then((new_response: any) => {

          const data = new_response?.payload?.data;
          if (data) {

            setFormData2((prev) => ({
              ...prev,
              ...data,
              ppsnumber: data.ppsNumber,
              townorcity: data.townOrCity,
              middleName: data.middle_name,
              employername: data.employerName,
              employerbusiness: data.employerBusiness,

            }));
            setsecondaryform((prev) => ({
              ...prev,
              ...data.joint_details,
            }));
            setUploadDependantPpc(data.joint_details);
            setUploadDependantPassport(data.joint_details);
            setUploadedFile(data.ppsNumberDoc);
            setUploadedPassport(data.passportDoc);
            setInvestAmount(data.investmentAmount);
            setFormData4((prev) => ({
              ...prev,
              ...data,
              inverstmentfromemploymentincome: data.investmentFromEmploymentIncome,
              invertmentfrompropertygains: data.investmentFromPropertyGains,
              inverstmentfromgifts: data.investmentFromGifts,
              inverstmentfrominverstmentgains: data.investmentFromInvestmentGains,
              inverstmentfromothers: data.investmentFromOthers,
              inverstmentfromothersinfo: data.inverstmentFromOthersInfo,
            }));
            setFormDataWorth((prev) => ({
              ...prev,
              ...data,
              totalnetworth: data?.totalNetWorth,
              liquidnetworth: data?.liquidNetWorth,
              totalnetincome: data?.totalNetIncome,
            }));
            setFormDataFamily((prev) => ({
              ...prev,
              ...data,

            }));
            setFormDataFamliyFinance((prev) => ({
              ...prev,
              ...data,

            }));
            setInteractive((prev) => ({
              ...prev,
              ...data,

            }));
          }
        })
        .catch((error) => {
          console.error('Failed to fetch account entity:', error);
        });
    }
  }, [accountsEntity,
    dispatch,
    setFormData2,
    setInvestAmount,
    setFormData4,
    setFormDataWorth,
    setFormDataFamily,
    setFormDataFamliyFinance,
    setInteractive,
    setsecondaryform,
    setUploadDependantPpc,
    setUploadDependantPassport,
    setUploadedFile,
    setUploadedPassport,

  ]);

  const [checkboxStates, setCheckboxStates] = useState({
    over18: false,
    beneficialOwner: false,
    residentIreland: false,
    taxResidentIreland: false,
    minimumInvestment: false,
    investmentObjective: false,
    StarlightBusinessTerms: false,
    StarlightRemuneration: false,
    StarlightWealthPrivacy: false,
    StarlightFieldConfirmation: false,
    diffMailing: false,
    diffCitizenship: false,
    bornOutside: false,
    statement: false,
  });

  const [mailing_address, setMailing_address] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })


  const [differentData, setDifferentData] = useState({
    signed_by: '',
    signed_date: '',
    mailing_address: '',
    ssn: '',
    ftn: false,
    reference_number: '',
    beneficial_owner_residence: '',
    article_and_paragraph: '',
    rate_of_with_holding: '',
    type_of_income: '',
    additional_condition: '',
    eligibility_explanation: ''
  });

  const [jointemploymentstatus, setjointEmploymentstatus] = useState('EMPLOYED');

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [showSourceModal, setShowSourceModal] = useState(false);

  const handleSourceCloseModal = () => {
    setShowSourceModal(false);
  };

  const [sosPrimary_sign, setsosPrimarySign] = useState<string | null>(null);
  const [sosSecondarySign, setsosSecondarySign] = useState<string | null>(null);
  const [ibkrPrimary_sign, setibkrPrimarySign] = useState<string | null>(null);
  const [ibkrSecondarySign, setibkrSecondarySign] = useState<string | null>(null);
  const [smart_reserve_primary_sign, setsmart_reserve_primary_sign] = useState<string | null>(null);
  const [smart_reserve_SecondarySign, setsmart_reserve_SecondarySign] = useState<string | null>(null);
  const [w8benPrimary_sign, setw8benPrimary_sign] = useState<string | null>(null);
  const [w8benSecondarySign, setw8benSecondarySign] = useState<string | null>(null);

  const handleSosSignatureUpdate = (signaturetype, signatureValue) => {

    let accountId = accountsEntity?.toString() || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }
    const payload = {
      accountId,
      signaturetype,
      signed_by: sosSecondarySign || sosPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'sos') {
          if (signatureValue === sosPrimary_sign) {
            setsosPrimarySign(updatedSignature?.primary_sign || sosPrimary_sign);
          } else {
            setsosSecondarySign(updatedSignature?.joint_sign || sosSecondarySign);
          }
        }

        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
        }
      });
    } catch (error) { /* empty */ }
  };

  const handleSmartReserveSignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const payload = {
      accountId: accountsEntity,
      signaturetype,
      signed_by: smart_reserve_SecondarySign || smart_reserve_primary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'smart_reserve') {
          if (signatureValue === smart_reserve_primary_sign) {
            setsmart_reserve_primary_sign(updatedSignature?.primary_sign || smart_reserve_primary_sign);
          } else {
            setsmart_reserve_SecondarySign(updatedSignature?.joint_sign || smart_reserve_SecondarySign);
          }
        }
        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
        }
      });
    }
    catch (error) { /* empty */ }
  };

  const handlew8benSignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const formPayload = {
      signed_by: w8benPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0], // Using the current date
      mailing_address: {
        street: mailing_address.street,
        city: mailing_address.city,
        state: mailing_address.state,
        postal_code: mailing_address.postal_code,
        country: mailing_address.country
      },
      ssn: differentData.ssn,
      ftn: true,
      reference_number: differentData.reference_number,
      beneficial_owner_residence: differentData.beneficial_owner_residence,
      article_and_paragraph: differentData.article_and_paragraph,
      rate_of_with_holding: differentData.rate_of_with_holding,
      type_of_income: differentData.type_of_income,
      additional_condition: differentData.additional_condition,
      eligibility_explanation: differentData.eligibility_explanation,
    };

    try {
      // dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })); // Pass formPayload as payload
      if (customerId === mainAccountsEntity.customerId) {
        dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })).then((response: any) => {
          const updatedSignature = response.payload;

          if (signaturetype === 'w8ben') {
            if (signatureValue === w8benPrimary_sign) {
              setw8benPrimary_sign(updatedSignature?.primary_sign || w8benPrimary_sign);
            }
          }
          if (updatedSignature) {
            handleUpdateNextStep(showDiv);
          }

        });
      }
    } catch (error) { /* empty */ }
  };


  const handlew8benSecondarySignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const formPayload = {
      signed_by: w8benSecondarySign,
      signed_date: new Date().toISOString().split('T')[0], // Using the current date
      mailing_address: {
        street: mailing_address.street,
        city: mailing_address.city,
        state: mailing_address.state,
        postal_code: mailing_address.postal_code,
        country: mailing_address.country
      },
      ssn: differentData.ssn,
      ftn: true,
      reference_number: differentData.reference_number,
      beneficial_owner_residence: differentData.beneficial_owner_residence,
      article_and_paragraph: differentData.article_and_paragraph,
      rate_of_with_holding: differentData.rate_of_with_holding,
      type_of_income: differentData.type_of_income,
      additional_condition: differentData.additional_condition,
      eligibility_explanation: differentData.eligibility_explanation,
    };

    try {
      if (customerId === mainAccountsEntity.secondary_customer_id) {
        dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })).then((response: any) => {
          const updatedSignature = response.payload;

          if (signaturetype === 'w8ben') {
            if (signatureValue === w8benPrimary_sign) {
              setw8benSecondarySign(updatedSignature?.joint_sign || w8benSecondarySign);
            }
          }
          if (updatedSignature) {
            handleUpdateNextStep(showDiv);
          }
        });
      }
    }
    catch (error) { /* empty */ }
  };
  const handleIBSignatureUpdate = (signaturetype, signatureValue) => {
    let accountId = accountsEntity?.toString() || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }

    const payload = {
      accountId,
      signaturetype,
      signed_by: ibkrSecondarySign || ibkrPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;


        if (signaturetype === 'ibkr') {
          if (signatureValue === ibkrPrimary_sign) {
            setibkrPrimarySign(updatedSignature?.primary_sign || ibkrPrimary_sign);
          } else {
            setibkrSecondarySign(updatedSignature?.joint_sign || ibkrSecondarySign);
          }
        }
        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
        }
      });
    } catch (error) { /* empty */ }
  };
  interface IValidationErrors {
    [key: string]: string;
  }

  const [countryList, setCountryList] = useState<string[]>([]);
  const [bankList, setBankList] = useState<string[]>([]);

  const [stateList, setStateList] = useState<string[]>([]);
  const [showDiv, setShowDiv] = useState(last_level);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {
    if (showDiv === 2 || showDiv === 3 || showDiv === 10) {
      if (accessToken) {
        dispatch(getCountryList()).then((res) => {
          dispatch(getCustomerDetails());

          if (res.payload) {
            const countryData = res.payload.map((item: any) => item);
            setCountryList(countryData);
          }

        });
      }
    }
  }, [showDiv, dispatch]);

  useEffect(() => {
    if (showDiv === 6 || showDiv === 10) {
      if (accessToken) {
        dispatch(getBankList()).then((res) => {
          dispatch(getCustomerDetails());

          if (res.payload) {
            const bankData = res.payload.map((item: any) => item);
            setBankList(bankData);
          }

        });
      }
    }
  }, [showDiv, dispatch]);

  useEffect(() => {
    if (showDiv === 2 || showDiv === 3 || showDiv === 10) {
      dispatch(getState()).then((res) => {


        dispatch(getCustomerDetails());

        if (res.payload) {
          const stateData = res.payload.map((item: any) => item);
          setStateList(stateData);
        }
      });
    }
  }, [showDiv, dispatch]);

  const handleContinue = () => {
    setOpen7(false);

    setOpen2(value2 => !value2);

    if (open7 === false) {
      setOpen2(true);
    }
  };

  const handleUpdateNextStep = async (_showDiv: number) => {

    let accountId = mainAccountsEntity?.accountId?.toString() || sessionStorage?.getItem('accountid') || pendingTaskAccountId?.[0]?.accountId || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }


    if (_showDiv === 2) {
      showDiv2Function(_showDiv);
    }

    if (_showDiv === 3) {
      showDiv3Function();
    }
    if (_showDiv === 4) {
      const updateBody = {
        accountId,
        investmentFromEmploymentIncome: formData4.inverstmentfromemploymentincome,
        investmentFromPropertyGains: formData4.invertmentfrompropertygains,
        investmentFromGifts: formData4.inverstmentfromgifts,
        investmentFromInvestmentGains: formData4.inverstmentfrominverstmentgains,
        investmentFromOthers: formData4.inverstmentfromothers,
        inverstmentFromOthersInfo: formData4.inverstmentfromothersinfo,
        last_level: 5,
      };
      const result: any = await dispatch(updateEntity(updateBody));

      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
      if (totalValueError === 'Total of the values must meet 100% (with other% if entered)' || totalValueError === 'Total of the values must meet 100% (without other% if not entered)') {

        setShowSourceModal(true);
      }

    } else if (_showDiv === 5) {
      const updateBody = {
        accountId,
        totalNetWorth: Number(FormDataNetworth.totalnetworth),
        liquidNetWorth: Number(FormDataNetworth.liquidnetworth),
        totalNetIncome: Number(FormDataNetworth.totalnetincome),
        last_level: 6,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    }

    else if (_showDiv === 6) {
      const updateBody = {
        accountId,
        isPep: FormDataFamily.isPep,
        isFamilyPep: FormDataFamily.isFamilyPep,
        familymemberpepdetails: FormDataFamily.familymemberpepdetails,
        last_level: 7,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    } else if (_showDiv === 7) {
      const updateBody = {
        accountId,
        employedfinancialservicefirm: FormDataFamliyFinance.employedfinancialservicefirm,
        whoemployedfinancialservicefirm: FormDataFamliyFinance.whoemployedfinancialservicefirm,
        familymemberrealtionship: FormDataFamliyFinance.familymemberrealtionship,
        complianceofficername: FormDataFamliyFinance.complianceofficername,
        complianceofficercontactnumber: FormDataFamliyFinance.complianceofficercontactnumber,
        complianceofficeremail: FormDataFamliyFinance.complianceofficeremail,
        last_level: 8,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    }
    else if (_showDiv === 8) {
      const updateBody = {
        accountId: mainAccountsEntity.accountId || location.state?.account_id,
        last_level: 100,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);

      if (pendingTaskAccountId?.[0]?.need_more_info_description) {
        navigate("/user-profile")
      } else {
        // setShowDiv((prev: number) => prev + 91);
        setShowDiv(100);
      }

      setOpen7(true);
      scrollToTop();

    } else if (_showDiv === 100) {
      if (docConfirmation) {
        const updateBody = {
          accountId: accountId || location?.state?.account_id,
          last_level: 101,
        };
        const response: any = await dispatch(updateEntity(updateBody));
        // handleSosSignatureUpdate('sos');
        setAccountType(response?.payload?.data?.accountHolderType);
        setOpen3(true);
        // setShowDiv((prev: number) => prev + 1);
        setShowDiv(101);


        scrollToTop();
      }

    } else if (_showDiv === 101) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 102,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      setOpen4(true);
      // setShowDiv((prev: number) => prev + 1);
      if (customerId !== mainAccountsEntity?.customerId) {
        setShowDiv(103)
      } else {
        setShowDiv(102);
      }

      scrollToTop();
    } else if (_showDiv === 102) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 103,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      // setShowDiv((prev: number) => prev + 1);
      // if (AccountType === 'Individual') {
      if (customerId === mainAccountsEntity?.customerId) {
        setShowDiv(104)
        // }
      } else {
        setShowDiv(103);
      }
      setUpdateSuccess(true);
      scrollToTop();
    }
    else if (_showDiv === 103) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 104,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      // setShowDiv((prev: number) => prev + 1);
      setShowDiv(104);
      setUpdateSuccess(true);
      scrollToTop();
    }
    else if (_showDiv === 104) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 105,
        ib_customer_agreement: interactive?.ib_customer_agreement,
        ib_client_financial_advisor: interactive?.ib_client_financial_advisor,
        elt: interactive?.elt,
        ibsd_notification: interactive?.ibsd_notification,
        lla: interactive?.lla,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      if (AccountType === 'Individual' || customerId !== mainAccountsEntity?.customerId) {
        setOpen(true);
      } else {
        setOpen5(true);
      }
      setUpdateSuccess(true);
      dispatch(reset());
      scrollToTop();
    }
  };

  const showDiv2Function = (lcl_showDiv: number) => {

    // const _errors: IValidationErrors = {};

    // if (!investmentAmount) {
    //   _errors.investmentAmount = 'This field is mandatory';
    // }
    // if (investError) {
    //   _errors.investError = 'Minimum value should be €50,000';
    // }
    // if (!FormData2.addressLine1) {
    //   _errors.addressLine1 = 'This field is mandatory';
    // }
    // if (!FormData2.townorcity) {
    //   _errors.townorcity = 'This field is mandatory';
    // }
    // if (!FormData2.postcode) {
    //   _errors.postcode = 'This field is mandatory';
    // }
    // if (!FormData2.ppsnumber || ppsError) {
    //   _errors.ppsnumber = 'This field is mandatory';
    // }
    // if (!uploadedFile) {
    //   _errors.uploadedFile = 'This field is mandatory';
    // }
    // if (!FormData2.passport_number) {
    //   _errors.passport_number = 'This field is mandatory';
    // }
    // if (!uploadedPassport) {
    //   _errors.uploadedPassport = 'This field is mandatory';
    // }
    // if (!FormData2.passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // }
    // if (FormData2.istaxresident === true && !FormData2.othertaxresidence) {
    //   _errors.othertaxresidence = 'This field is mandatory';
    // }

    // if (employment_status === "EMPLOYED") {
    //   if (!FormData2.occupation) {
    //     _errors.occupation = 'This field is mandatory';
    //   }
    //   if (!FormData2.employername) {
    //     _errors.employername = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerbusiness) {
    //     _errors.employerbusiness = 'This field is mandatory';
    //   }
    //   // if (!FormData2.employerAddress?.country) {
    //   //   _errors.country = 'This field is mandatory';
    //   // }
    //   // if (!FormData2.employerAddress?.state) {
    //   //   _errors.state = 'This field is mandatory';
    //   // }
    //   if (!FormData2.employerAddress?.city) {
    //     _errors.city = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerAddress?.postal_code) {
    //     _errors.postal_code = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerAddress?.street) {
    //     _errors.street = 'This field is mandatory';
    //   }
    //   // if (!FormData2.isapubliclylistedcompany) {
    //   //   _errors.isapubliclylistedcompany = 'This field is mandatory';
    //   // }
    // }
    // if (Object.keys(_errors).length > 0) {
    //   setValidationErrors(_errors);


    //   setToastMessage((prev) => 'Please fill all the mandatory fields');
    //   setToastType('error');
    //   setToastVisible(true);
    //   return;
    // }
    // setValidationErrors({});

    const updateBody = {
      accountId: mainAccountsEntity.accountId || pendingTaskAccountId?.[0]?.accountId,
      accountHolderTitle: FormData2.nametitle,
      accountHolderEmail: email,
      accountHolderType: AccountType,
      accountHolderSurname: lastName,
      accountHolderName: name,
      middle_name,
      investmentAmount,
      accountHolderMaritalStatus: FormData2.maritalstatus,
      accountHolderDependents: FormData2.dependents,
      accountHolderMobileNumber: phone,
      addressLine1: FormData2.addressLine1,
      addressLine2: FormData2.addressLine2,
      townOrCity: FormData2.townorcity,
      country: FormData2.country,
      postcode: FormData2.postcode,
      ppsNumber: FormData2.ppsnumber,
      istaxresident: FormData2.istaxresident,
      othertaxresidence: FormData2.othertaxresidence,
      employment_status,
      occupation: FormData2.occupation,
      employerName: FormData2.employername,
      employerBusiness: FormData2.employerbusiness,
      employerAddress: FormData2.employerAddress,
      isapubliclylistedcompany: FormData2.isapubliclylistedcompany,
      ppsNumberDoc: uploadedFile,
      passportDoc: uploadedPassport,
      passport_number: FormData2.passport_number,
      passport_expiry_date: FormData2.passport_expiry_date,
      issued_country: FormData2.issued_country,
      state_or_province: FormData2.state_or_province,
      last_level: 3,
      mailing_address: {
        country: newcitizen.country,
        addressLineOne: newcitizen.addressLineOne,
        addressLineTwo: newcitizen.addressLineTwo,
        city: newcitizen.city,
        stateOrProvince: FormData2.state_or_province,
        zipOrPostalCode: FormData2.postcode,
      },
      birth_info: {
        countryOfBirth: newcitizen?.birth_info?.countryOfBirth,
      },
    };

    dispatch(updateEntity(updateBody));
    if (lcl_showDiv === 2) {
      if (AccountType === 'Individual') {
        setShowDiv(4);
        scrollToTop();
        return;
      } else {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  }

  const showDiv3Function = async () => {
    const _errors: IValidationErrors = {};

    if (!secondaryform.jointaddressline1) {
      _errors.jointaddressline1 = 'This field is mandatory';
    }
    if (!secondaryform.jointfirstname) {
      _errors.jointfirstname = 'This field is mandatory';
    }
    if (!secondaryform.jointsurname) {
      _errors.jointsurname = 'This field is mandatory';
    }
    if (!secondaryform.joint_username) {
      _errors.joint_username = 'This field is mandatory';
    }
    if ((!secondaryform.jointmobilenumber || secondaryform.jointmobilenumber === '+353') || secondaryform.jointmobilenumber.length !== 13) {
      _errors.jointmobilenumber = 'Please enter a valid mobile number starting with +353 and up to 9 digits.';
    }




    if (!secondaryform.jointppsnumber) {
      _errors.jointppsnumber = 'This field is mandatory';
    }
    if (!secondaryform.dependendPassportNumber) {
      _errors.dependendPassportNumber = 'This field is mandatory';
    }
    if (!joint_pps_doc) {
      _errors.joint_pps_doc = 'This field is mandatory';
    }
    if (!joint_passport_doc) {
      _errors.joint_passport_doc = 'This field is mandatory';
    }
    if (!secondaryform.jointtownorcity) {
      _errors.jointtownorcity = 'This field is mandatory';
    }
    if (!secondaryform.jointpostcode) {
      _errors.jointpostcode = 'This field is mandatory';
    }
    if (!joint_pps_doc) {
      _errors.joint_pps_doc = 'This field is mandatory';
    }
    if (!joint_passport_doc) {
      _errors.joint_passport_doc = 'This field is mandatory';
    }
    if (!secondaryform.jointpassport_expiry_date) {
      _errors.jointpassport_expiry_date = 'This field is mandatory';
    }
    if (jointemploymentstatus === "EMPLOYED") {
      if (!secondaryform.jointoccupation) {
        _errors.jointoccupation = 'This field is mandatory';
      }
      if (!secondaryform.jointemployername) {
        _errors.jointemployername = 'This field is mandatory';
      }
      if (secondaryform.jointistaxresident === true && !secondaryform.jointothertaxresidence) {
        _errors.jointothertaxresidence = 'This field is mandatory';
      }
      if (!secondaryform.jointemployerbusiness) {
        _errors.jointemployerbusiness = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.city) {
        _errors.city = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.postal_code) {
        _errors.postal_code = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.street) {
        _errors.street = 'This field is mandatory';
      }
    }
    if (Object.keys(_errors).length > 0) {
      setsecondValidationErrors(_errors);
      // toast.error('Please fill all the mandatory fields', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'custom-toast'
      // });
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);

      return;
    }
    setsecondValidationErrors({});
    const body: any = {
      accountId: mainAccountsEntity.accountId,
      is_joint_account: true,
      joint_details: {
        jointnametitle: secondaryform.jointnametitle,
        jointfirstname: secondaryform.jointfirstname,
        jointsurname: secondaryform.jointsurname,
        jointemail: secondaryform.jointemail,
        jointdob: secondaryform.jointdob,
        jointmaritalstatus: secondaryform.jointmaritalstatus,
        jointdependents: secondaryform.jointdependents,
        joint_username: secondaryform.joint_username,
        jointmobilenumber: secondaryform.jointmobilenumber,
        jointaddressline1: secondaryform.jointaddressline1,
        jointaddressline2: secondaryform.jointaddressline2,
        jointtownorcity: secondaryform.jointtownorcity,
        jointcountry: secondaryform.jointcountry,
        jointpostcode: secondaryform.jointpostcode,
        jointppsnumber: secondaryform.jointppsnumber,
        jointistaxresident: secondaryform.jointistaxresident,
        jointothertaxresidence: secondaryform.jointothertaxresidence,
        dependendPassportNumber: secondaryform.dependendPassportNumber,
        jointemploymentstatus,
        jointoccupation: secondaryform.jointoccupation,
        jointemployername: secondaryform.jointemployername,
        jointemployerbusiness: secondaryform.jointemployerbusiness,
        jointemployeraddress: secondaryform.jointemployeraddress,
        jointpassport_expiry_date: secondaryform.jointpassport_expiry_date,
        jointisapubliclylistedcompany: secondaryform.jointisapubliclylistedcompany,
        jointstateorprovince: secondaryform.jointstateorprovince,
        jointcountryofbirth: secondaryform.jointcountryofbirth,
        jointissuedcountry: secondaryform.jointissuedcountry,
        joint_pps_doc,
        joint_passport_doc,
        jointownership: 50,
        last_level: 4,
      },
    };

    const result: any = await dispatch(updateEntity(body));
    if (result?.payload?.data) {
      setAccountId(result?.payload?.data?.accountId);
      setCountry(result?.payload?.data?.country);
      setShowDiv((prev: number) => prev + 1);
      setOpen6(true);
      setToastMessage((prev) => 'Please check your email for a secure code');
      setToastType('success');
      setToastVisible(true);
      scrollToTop();
    }
  }

  const handlePreviousStep = (_showDiv: number) => {

    setShowDiv((prev: number) => prev - 1);
    if (mainAccountsEntity?.accountHolderType === 'Individual') {
      if (_showDiv === 4) {
        setShowDiv((prev: number) => prev - 1);
        scrollToTop();
        return;
      }
    }
    if (mainAccountsEntity?.accountHolderType === 'Joint') {
      if (_showDiv === 4) {
        setShowDiv(3);
        scrollToTop();
        return;
      }
    }

    if (_showDiv === 100) {
      setShowDiv((prev: number) => prev - 91);
    }
    const target1 = document.querySelector('#topSectionHolder');
    if (target1) {
      target1.scrollIntoView({ behavior: 'smooth' });
    }

    if (_showDiv === 104) {
      if (customerId === mainAccountsEntity?.customerId) {
        setShowDiv((prev: number) => prev - 1);
      }
    }
    const target2 = document.querySelector('#topSectionHolder');
    if (target2) {
      target2.scrollIntoView({ behavior: 'smooth' });
    }

    if (_showDiv === 103) {
      if (customerId !== mainAccountsEntity?.customerId) {
        setShowDiv((prev: number) => prev - 1);
      }
    }
    scrollToTop();

  };

  const validateTotalPercentage = () => {
    const {
      invertmentfrompropertygains,
      inverstmentfromgifts,
      inverstmentfrominverstmentgains,
      inverstmentfromothers,
      inverstmentfromemploymentincome,
    } = formData4;

    const totalPercentage =
      Number(invertmentfrompropertygains || '0') +
      Number(inverstmentfromgifts || '0') +
      Number(inverstmentfrominverstmentgains || '0') +
      Number(inverstmentfromemploymentincome || '0') +
      (inverstmentfromothers ? Number(inverstmentfromothers) : 0);

    if (inverstmentfromothers) {
      if (totalPercentage !== 100) {
        setTotalValueError('Total of the values must meet 100% (with other% if entered)');
      } else {
        setTotalValueError('');
      }
    } else {
      const totalWithoutOthers =
        Number(invertmentfrompropertygains || '0') +
        Number(inverstmentfromgifts || '0') +
        Number(inverstmentfrominverstmentgains || '0') +
        Number(inverstmentfromemploymentincome || '0');

      if (totalWithoutOthers !== 100) {
        setTotalValueError('Total of the values must meet 100% (without other% if not entered)');
      } else {
        setTotalValueError('');
      }
    }
  };

  useEffect(() => {
    validateTotalPercentage();
  }, [formData4]);

  const handleCheckboxChangeThree = e => {
    setDocConfirmation3({ ...docConfirmation3, statement3: e.target.checked });
  };
  //last  form

  const handleCheckboxibustomerAgreement = e => {
    setInteractive({ ...interactive, ib_customer_agreement: e.target.checked });
  };

  const handleCheckboxibclientFinancialAdvisor = e => {
    setInteractive({ ...interactive, ib_client_financial_advisor: e.target.checked });
  };
  const handleCheckboxelt = e => {
    setInteractive({ ...interactive, elt: e.target.checked });
  };
  const handleCheckboxibsdNotification = e => {
    setInteractive({ ...interactive, ibsd_notification: e.target.checked });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCheckboxLLA = e => {
    setInteractive({ ...interactive, lla: e.target.checked });
  };

  //================================================================//
  // Latest Changes / Bug Fixes from InitialRequirement component layer
  //================================================================//

  const initialRequirementRef = useRef<{ syncInitialRequirementWithServer: () => Promise<any> }>(null);
  const [initialRequirementButton, setInitialRequirementButton] = useState<boolean>(false);

  const handleInitialRequirementSubmit = async (): Promise<void> => {
    if (initialRequirementRef.current) {
      const response = await initialRequirementRef.current.syncInitialRequirementWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };
  //================================================================//
  // Latest Changes / Bug Fixes from PrimaryApplication component layer
  //================================================================//
  const primaryDetailsRef = useRef<{ syncPrimaryDetailsWithServer: () => Promise<any> }>(null);
  const [primaryDetailsButton, setPrimaryDetailsButton] = useState<boolean>(false);

  const handlePrimaryDetailsButtonSubmit = async (): Promise<void> => {
    if (primaryDetailsRef.current) {
      const response = await primaryDetailsRef.current.syncPrimaryDetailsWithServer();

      if (response !== null) {
        if (showDiv === 2) {
          if (mainAccountsEntity?.accountHolderType === 'Individual') {
            setShowDiv(4);
          } else {
            setShowDiv((prev: number) => prev + 1);
          }
          scrollToTop();
        }
      }

    }
  };

  //Secondary
  const secondaryDetailsRef = useRef<{ syncSecondaryDetailsWithServer: () => Promise<any> }>(null);
  const [secondaryDetailsButton, setSecondaryDetailsButton] = useState<boolean>(false);

  const handleSecondaryDetailsButtonSubmit = async (): Promise<void> => {
    if (secondaryDetailsRef.current) {
      try {
        const response = await secondaryDetailsRef.current.syncSecondaryDetailsWithServer();


        if (response?.payload?.status===400) {
 
          setToastMessage((prev) => "Invalid mobile number");
          setToastType('error');
          setToastVisible(true);
         
        }

        if (response.payload.data.id) {

          setShowDiv((prev: number) => prev + 1);
          setOpen6(true);
          // setToastMessage('Please check your email for a secure code');
          // setToastType('success');
          // setToastVisible(true);
          scrollToTop();
        } else {
          // Handle other response statuses if necessary
        }
      } catch (error) {
        // Handle error if necessary
        console.error('Error:', error);
      }
    }
  };


  //================================================================//
  // Latest Changes / Bug Fixes from AntiMoney component layer
  //================================================================//


  const antiMoneyRef = useRef<{ syncAntiMoneyWithServer: () => Promise<any> }>(null);
  const [antiMoneyButton, setAntiMoneyButton] = useState<boolean>(false);

  const handleAntiMoneySubmit = async (): Promise<void> => {
    if (antiMoneyRef.current) {
      const response = await antiMoneyRef.current.syncAntiMoneyWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  //================================================================//
  // Latest Changes / Bug Fixes from NetWorthAnnual component layer
  //================================================================//


  const netWorthAnnualRef = useRef<{ syncNetWorthAnnualWithServer: () => Promise<any> }>(null);
  const [netWorthAnnualButton, setNetWorthAnnualButton] = useState<boolean>(false);

  const handleNetWorthAnnualSubmit = async (): Promise<void> => {
    if (netWorthAnnualRef.current) {
      const response = await netWorthAnnualRef.current.syncNetWorthAnnualWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };
  const [secondaryId, setSecondaryId] = useState()

  //================================================================//
  // Latest Changes / Bug Fixes from PEP component layer
  //================================================================//


  const pepRef = useRef<{ syncPepWithServer: () => Promise<any> }>(null);

  const handlePEPSubmit = async (): Promise<void> => {
    if (pepRef.current) {
      const response = await pepRef.current.syncPepWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };


  //================================================================//
  // Latest Changes / Bug Fixes from FinancialServices component layer
  //================================================================//


  const financialServicesRef = useRef<{ syncFinancialServicesWithServer: () => Promise<any> }>(null);
  const [financialServicesButton, setFinancialServicesButton] = useState<boolean>(false);


  const handleFinancialServicesSubmit = async (): Promise<void> => {
    if (financialServicesRef.current) {
      const response = await financialServicesRef.current.syncFinancialServicesWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  //================================================================//
  // Latest Changes / Bug Fixes from AccountSummary component layer
  //================================================================//


  const accountSummaryRef = useRef<{ syncAccountSummaryWithServer: () => Promise<any> }>(null);
  const [accountSummaryButton, setAccountSummaryButton] = useState<boolean>(false);

  const handleAccountSummarySubmit = async (): Promise<void> => {
    if (accountSummaryRef.current) {
      const response = await accountSummaryRef.current.syncAccountSummaryWithServer();

      if (response) {
        setShowDiv(100);
        scrollToTop();
      }
      setOpen7(true);
    }
  };

  const totalSteps = 13;

  const calculateProgress = (currentStep: number): number => {
    if (isNaN(currentStep) || currentStep < 1) {
      return 0;
    }
    if (currentStep >= totalSteps) {
      return 100;
    }
    return ((currentStep - 1) / totalSteps) * 100;
  };


  useEffect(() => {
    setShowdivprogress(calculateProgress(PROGRESS_PERCENTAGE[showDiv]));
    setShowdivper(Math.round(calculateProgress(PROGRESS_PERCENTAGE[showDiv])));
  }, [showDiv]);

  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      <Row>
        <Col md="12" className="custom-bg-account ">
          <div className="d-md-flex justify-content-md-center">
            <Form className="form-account-custom bg-white">
            <Row className="text-center">
                <span className="text-start">{showdivper} % completed</span>
                <Col md="12" className="pt-3">
                  <Progress className="Progress-account-custom" value={showdivprogress} max="100" />
                </Col>
              </Row>
              <div>
                {showDiv === 1 && (
                  <div>
                    <InitialRequirementCheck ref={initialRequirementRef} onFormComplete={(status) => { setInitialRequirementButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-3 mb-3"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div>
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => {
                              // handleNextStep(showDiv);
                              handleInitialRequirementSubmit();
                            }}
                            disabled={!initialRequirementButton}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {showDiv === 2 && (
                  <div>
                    <Row>
                      <PrimaryApplication ref={primaryDetailsRef} onFormComplete={(status) => { setPrimaryDetailsButton(status) }}

                      />
                      <Row>
                        <FormGroup>
                          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <Col md="12 d-flex justify-content-md-end pt-3">
                          <div className="p-1">
                            <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                              Previous
                            </Button>
                          </div>

                          {mainAccountsEntity?.accountHolderType === "Individual" ? (
                            <div className='p-1'>
                              <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDetailsButtonSubmit(); }}
                              >
                                Continue
                              </Button>
                            </div>

                          ) : (
                            <div className='p-1'>
                              <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDetailsButtonSubmit(); }}
                              >
                                Continue
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Row>
                  </div>
                )}

                {showDiv === 3 && (
                  <div>
                    <SecondaryAplicaion
                      ref={secondaryDetailsRef} onFormComplete={(status) => { setSecondaryDetailsButton(status) }}
                    />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>

                        <div className='p-1'>
                          <Button
                            className="button-account-custom-submit"
                            id="continueButton"
                            onClick={() => { handleSecondaryDetailsButtonSubmit(); }}
                          // disabled={!secondaryDetailsButton}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {showDiv === 4 && (
                  <div>
                    <AntiMoney ref={antiMoneyRef} onFormComplete={(status) => { setAntiMoneyButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>

                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div  className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>
                        <div className="p-1">
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => {
                              // handleUpdateNextStep(showDiv);
                              handleAntiMoneySubmit();
                            }}
                            disabled={!antiMoneyButton}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Modal show={showSourceModal} onHide={handleSourceCloseModal}>
                      <ModalHeader closeButton>
                        Validation Error
                      </ModalHeader>
                      <ModalBody>{totalValueError}</ModalBody>
                      <ModalFooter>
                        <Button variant="secondary" onClick={handleSourceCloseModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>



                  </div>
                )}

                {showDiv === 5 && (
                  <div>
                    <>
                      <NetWorthAnnualIncome ref={netWorthAnnualRef} onFormComplete={(status) => { setNetWorthAnnualButton(status) }} />
                      <Row>
                        <FormGroup>
                          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <Col md="12 d-flex justify-content-md-end pt-3">
                          <div className="p-1">
                            <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                              Previous
                            </Button>
                          </div>
                          <div className='p-1'>
                            <Button
                              className="button-account-custom-submit"
                              onClick={() => {
                                // handleUpdateNextStep(showDiv);
                                handleNetWorthAnnualSubmit()
                              }}
                              disabled={!(netWorthAnnualButton)}
                            >
                              Continue
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  </div>
                )}


                {showDiv === 6 && (
                  <div>
                    <Pep ref={pepRef} onFormComplete={(status) => { }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>
                        <div className='p-1'>
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => {
                              // handleUpdateNextStep(showDiv);
                              handlePEPSubmit();
                            }}
                            disabled={
                              (mainAccountsEntity.isPep === false || mainAccountsEntity.isFamilyPep === false) &&
                              !mainAccountsEntity.familymemberpepdetails
                            }
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {showDiv === 7 && (
                  <div>
                    <FinancialServices ref={financialServicesRef} onFormComplete={(status) => { setFinancialServicesButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>
                        <div className='p-1'>
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => {
                              handleFinancialServicesSubmit();
                            }}

                          >

                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {showDiv === 8 && (
                  <div>
                    <AccountSummary ref={accountSummaryRef} onFormComplete={(status) => { setAccountSummaryButton(status) }}
                    />
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>
                        <div className="p-1">

                          {pendingTaskAccountId?.[0]?.need_more_info_description ? (
                            <Button
                              className="button-account-custom-submit"
                              onClick={() => { handleAccountSummarySubmit() }}
                              disabled={isEditing}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              className="button-account-custom-submit"
                              onClick={() => { handleAccountSummarySubmit() }}
                              disabled={isEditing}
                            >
                              Continue
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {showDiv === 100 && (
                  <>
                   <SosDoc
                      FName={name}
                      LName={lastName}
                      invest={investmentAmount}
                      accountIdNum={accountIdNum}
                      docConfirmation={docConfirmation}
                      setDocConfirmation={setDocConfirmation}
                      sosPrimary_sign={sosPrimary_sign}
                      sosSecondarySign={sosSecondarySign}
                      accountHolderType={AccountType}
                      setsosPrimarySign={setsosPrimarySign}
                      setsosSecondarySign={setsosSecondarySign}
                    />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)} disabled={customerId !== mainAccountsEntity?.customerId} >
                            Previous
                          </Button>
                        </div>
                        <div className='p-1'>
                          <Button
                            className="button-account-custom-submit"
                            id="continueButton"

                            onClick={() => {
                              handleSosSignatureUpdate('sos', sosPrimary_sign);
                            }}
                            // disabled={!(sosPrimary_sign)}
                            disabled={mainAccountsEntity.customerId === customerId ? !(sosPrimary_sign) : !(sosSecondarySign)}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>

                )}

                {showDiv === 101 && (
                  <>
                    <SecondDoc
                      accountHolderType={AccountType}
                      lastName={lastName}
                      name={name}
                      secondaryform={secondaryform}
                      smart_reserve_primary_sign={smart_reserve_primary_sign}
                      setsmart_reserve_primary_sign={setsmart_reserve_primary_sign}
                      smart_reserve_SecondarySign={smart_reserve_SecondarySign}
                      setsmart_reserve_SecondarySign={setsmart_reserve_SecondarySign}
                    />

                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12 d-flex justify-content-md-end pt-3">
                        <div className="p-1">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            Previous
                          </Button>
                        </div>
                        <div className='p-1'>
                          <Button
                            className="button-account-custom-submit"
                            id="continueButton"
                            onClick={() => {
                              handleSmartReserveSignatureUpdate('smart_reserve', smart_reserve_primary_sign);
                            }}
                            // disabled={!(smart_reserve_primary_sign)}
                            disabled={mainAccountsEntity.customerId === customerId ? !(smart_reserve_primary_sign) : !(smart_reserve_SecondarySign)}
                          >
                            Continue
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {showDiv === 102 && (
                  <>
                    <FormDoc
                      docConfirmation3={docConfirmation3}
                      handleCheckboxChangeThree={handleCheckboxChangeThree}
                      handleUpdateNextStep={handleUpdateNextStep}
                      FormData2={FormData2}
                      email={email}
                      name={name}
                      accountHolderType={AccountType}
                      w8benPrimary_sign={w8benPrimary_sign}
                      setw8benPrimary_sign={setw8benPrimary_sign}
                      w8benSecondarySign={w8benSecondarySign}
                      setw8benSecondarySign={setw8benSecondarySign}
                      setEmail={setEmail}
                      setDifferentData={setDifferentData}
                      setMailing_address={setMailing_address}
                      mailing_address={mailing_address}
                      differentData={differentData}
                    />


                    <Row className="formDoc-button-arrangement">
                      <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className='p-1'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handlew8benSignatureUpdate('w8ben', w8benPrimary_sign);
                          }}
                          //disabled={!w8benPrimary_sign}
                          disabled={mainAccountsEntity.customerId === customerId ? !(w8benPrimary_sign) : !(w8benSecondarySign)}


                        >
                          Continue
                        </Button>
                      </div>
                      </Col>
                    </Row>

                  </>
                )}
                {showDiv === 103 && (
                  <>
                    <W8benSecondary
                      docConfirmation3={docConfirmation3}
                      handleCheckboxChangeThree={handleCheckboxChangeThree}
                      handleUpdateNextStep={handleUpdateNextStep}
                      FormData2={FormData2}
                      email={email}
                      secondaryform={secondaryform}
                      setsecondaryform={setsecondaryform}
                      name={name}
                      accountHolderType={AccountType}
                      w8benPrimary_sign={w8benPrimary_sign}
                      setw8benPrimary_sign={setw8benPrimary_sign}
                      w8benSecondarySign={w8benSecondarySign}
                      setw8benSecondarySign={setw8benSecondarySign}
                      setEmail={setEmail}
                      setDifferentData={setDifferentData}
                      differentData={differentData}
                      mailing_address={mailing_address}
                      setMailing_address={setMailing_address}
                    />
                    <Row className="formDoc-button-arrangement">
                      <Col md="12 d-flex justify-content-md-end pt-3">
                      <div className="p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className='p-1'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handlew8benSecondarySignatureUpdate('w8ben', w8benSecondarySign);
                            handleUpdateNextStep(showDiv);
                          }}
                          //disabled={!w8benPrimary_sign}
                          disabled={mainAccountsEntity.customerId === customerId ? !(w8benPrimary_sign) : !(w8benSecondarySign)}

                        >
                          Continue
                        </Button>
                      </div>
                      </Col>
                    </Row>
                  </>
                )}
                {showDiv === 104 && (
                  <>
                    <InteractiveBrokers
                      interactive={interactive}
                      accountIdNum={accountIdNum}
                      accountHolderType={mainAccountsEntity?.accountHolderType || AccountType}
                      ibkrSecondarySign={ibkrSecondarySign}
                      setibkrSecondarySign={setibkrSecondarySign}
                      ibkrPrimary_sign={ibkrPrimary_sign}
                      setibkrPrimarySign={(data)=>{setibkrPrimarySign(data)}}
                      handleCheckboxibustomerAgreement={handleCheckboxibustomerAgreement}
                      handleCheckboxibclientFinancialAdvisor={handleCheckboxibclientFinancialAdvisor}
                      handleCheckboxelt={handleCheckboxelt}
                      handleCheckboxibsdNotification={handleCheckboxibsdNotification}
                      handleCheckboxLLA={handleCheckboxLLA}
                    />

                    <Row className="formDoc-button-arrangement">
                      <Col md="12 "className="d-flex justify-content-md-end pt-3">
                      <div className=" p-1">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          Previous
                        </Button>
                      </div>
                      <div className='p-1'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"

                          onClick={() => {
                            handleIBSignatureUpdate('ibkr', ibkrPrimary_sign);
                          }}
                          // disabled={!(interactive.elt && interactive.ib_client_financial_advisor && interactive.ib_customer_agreement
                          //   && interactive.ibsd_notification && interactive.lla && ibkrPrimary_sign === null
                          // )}

                          disabled={
                            !(interactive.elt &&
                              interactive.ib_client_financial_advisor &&
                              interactive.ib_customer_agreement &&
                              interactive.ibsd_notification &&
                              interactive.lla) ||
                            (mainAccountsEntity.customerId === customerId ? !(ibkrPrimary_sign) : !(ibkrSecondarySign))
                          }

                        >
                          Submit
                        </Button>
                      </div></Col>
                    </Row>
                  </>
                )}
              </div>
            </Form>
          </div>
        </Col >
      </Row >

      <Modal show={!checkboxStates.StarlightFieldConfirmation && showModal} onHide={handleCloseModal}>
        <ModalHeader closeButton>
          <span>Reminder</span>
        </ModalHeader>
        <ModalBody>Please confirm the checkbox before proceeding.</ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>


      <CommonToast
        visible={open6}
        onClose={toggle6}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="custom-content">

          <div className="font-weight-bold custom-modal-style text-start" style={{ textAlign: 'left', margin: '0' }}>
            Thank you for providing the details of the Secondary Applicant for your Joint Starlight Account. As this is a joint application, the Secondary Applicant needs to sign the required agreements. Once you have completed the application, the Secondary Applicant will automatically receive an email at <span className='email-modal-style'>{mainAccountsEntity?.joint_details?.joint_username}</span> with instructions on how to sign the agreements.
          </div>
          <br />
          <Button color="primary" onClick={toggle6}>Continue</Button>
        </div>
      </CommonToast>

      <CommonToast
        visible={open5}
        onClose={toggle5}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        {updateSuccess && (
          <Row className=' '>
            <Col  className="col-12 font-weight-bold custom-modal-style " >
                <div style={{ fontWeight: 600 }}>Thank you for applying for a<br />Starlight Smart Reserve Account</div><br /><br />
                <div className='text-start'>
                Your application has been saved. Your application will be submitted once the Secondary Applicant has signed the agreements. We will update you shortly. <br />You can also check your account status in your&nbsp;
                <Link to="/user-profile" className="" style={{ color: '#597177' }}>
                  Client Portal
                </Link>.<br /><br />
                </div>
                <div  className='text-start'>
                We look forward to helping you create a brighter future for you and your family
                </div>
                {/* {`Here's to a brighter future!`} {`The Starlight Wealth team `} */}
                <br />
                <div className='justify-content-center pt-3'>
                  <Button color="primary" onClick={toggle5} >
                    <a rel="noreferrer" href="https://www.starlightwealth.ie" style={{ color: '#eee' }}>Close</a>
                  </Button>

                </div>
            </Col>
          </Row>
        )}
      </CommonToast>

      <CommonToast
        visible={open}
        onClose={toggle}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        {updateSuccess && (
          <Row className='justify-content-center '>
            <Col className='col-12'>
              <div className="font-weight-bold custom-modal-style text-center" style={{ margin: '0' }}>
                <div className='text-center'>
                  <span style={{ fontWeight: 600 }}>Thank you for applying for a<br />Starlight Smart Reserve Account</span><br /><br />
                </div>
                <div>
                Your application has been submitted. We will update you about your account status shortly.
                You can check your account status in <Link to="/user-profile" className="" style={{ color: '#597177' }}>
                  Client Portal
                </Link>.
                <br /><br />
                </div>
                We look forward to helping you create a brighter future for you and your family
                {/* {`Here's to a brighter future!`} {`The Starlight Wealth team`} */}
                <div className='d-flex align-items-center justify-content-center pt-3'>
                  <Button color="primary" onClick={toggle5} >
                    <a rel="noreferrer" href="https://www.starlightwealth.ie" style={{ color: '#eee' }}>Close</a>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </CommonToast>


      <CommonToast
        visible={open7}

        title={'Thank You For Completing The Application Form'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="font-weight-bold custom-modal-style-big text-start" >

          There are a few documents which we need you to sign before we can open your account.&nbsp;Please review each document on the following screens and sign where indicated by typing your <span className='' style={{ color: 'black', fontWeight: 'bold' }}>full name</span> in the box provided.

        </div>
        <br />
        <Button color="primary" onClick={handleContinue}>Continue</Button>
      </CommonToast>


      <CommonToast
        visible={open2}
        title={'Your Statement of Suitability'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="font-weight-bold  custom-modal-style text-start">
          <div className='' style={{ fontWeight: 600, textAlign: 'left', marginBottom: '10px' }}> </div>
          A Statement of Suitability is required by Irish regulation and sets out the reasons why the products or services offered or recommended by us are considered suitable for your particular needs, objectives, and circumstances. Please review the document and sign to confirm your agreement.
        </div>
        <br />
        <Button color="primary" onClick={toggle2}>
          Continue
        </Button>
      </CommonToast>


      <CommonToast
        visible={open3}
        // onClose={toggle7}
        title={'Starlight Smart Reserve Model Portfolio Description'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="font-weight-bold custom-modal-style text-start" >

          This document provides you with more information on how your funds will be invested.
          This model portfolio of short-term Money Market Funds is designed for clients seeking to generate an institutional rate of return on their cash, that exceeds prevailing bank deposit rates, but with a similar risk profile.
          Please review the document, scroll down to the bottom and sign to confirm your agreement.
        </div>
        <br />
        <Button color="primary" onClick={toggle3}>
          Continue
        </Button>
      </CommonToast>



      <CommonToast
        visible={open4}

        title={'W-8BEN Form'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="font-weight-bold custom-modal-style text-start" >
          <div className='' style={{ fontWeight: 600 }}> </div>

          A W-8BEN Form is US Tax form. It enables Irish residents to receive preferential tax treatment in certain circumstances. You are required to complete this form to open a <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Starlight Smart Account</span>. For more information on W-8BEN Forms please click
          &nbsp;
          <a href="https://www.starlightwealth.ie/faqs" target="_blank" style={{ color: '#597177', }} rel="noreferrer">
            here
          </a>

        </div>
        <br />
        <Button color="primary" onClick={toggle4}>
          Continue
        </Button>
      </CommonToast>



    </div >
  );
};

export default Accounts;
